import React, {useState} from 'react'
import { useFormik } from 'formik';
import Overlay from '../overlay';
import { useSelector } from 'react-redux';
import mode from '../Mode';
import axios from 'axios';
import Toast from '../Toaster';
import { toast } from 'react-hot-toast';
import ReactLoading from 'react-loading';
import { PaymentSchema } from '../../schemas';

const MakePayments = () => {
    const member = useSelector((state)=>state.auth.member);
    const [isLoading, setIsLoading] = useState(false);

    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/' : 'http://127.0.0.1:8000/';

    const onSubmit = () =>{
        setIsLoading(true)
        var phoneNu = values.phone
        var updated_num = "+254" + phoneNu[1]+ phoneNu[2]+ phoneNu[3]+ phoneNu[4]+ phoneNu[5]+ phoneNu[6]+ phoneNu[7]+ phoneNu[8]+ phoneNu[9]
        axios.post(baseurl+ 'payments/checkout/', {
            phone_number: updated_num,
            amount: Number(values.amount),
            reference: values.contribution,
            description: values.contribution,
            billref: values.contribution
        }).then((resp)=>{
            toast.success('An Mpesa prompt has been sent to your phone', {
                position: 'top-right',
                duration: 7500,
                style: {
                    background: 'greenYellow',
                    color: '#fff',
                    fontSize: '1.15em'
                },
            })
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            toast.error('This feature will be available from tomorrow')
            setIsLoading(false)
        })
    }

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            phone: '',
            contribution: '',
            amount: ''
        },
        validationSchema: PaymentSchema,
        onSubmit
    })
    return(
        <div className='App'>
            <Overlay/>
            <Toast/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='main-content'>
                <div className='content-section'>
                    <div className='row justify-content-center'>
                        <div className="content col-md-7 m-auto" style={{backgroundColor: "white"}}>
                            <form className='forms'>
                                <fieldset>
                                    <legend>Make Payment</legend>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label className='form-control-lg'>Contribution</label>
                                            <select
                                                type='text'
                                                className='form-control'
                                                name='contribution'
                                                value={values.contribution}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <option></option>
                                                <option value='Tithe'>Tithe</option>
                                                <option value='Offering'>Offering</option>
                                                <option value='Mission'>Mission</option>
                                                <option value='Camp Expense'>Camp Expense</option>
                                                <option value='Camp Offering'>Camp Offering</option>
                                            </select>
                                            { errors.contribution && touched.contribution && <p className='error'>{errors.contribution}</p>}
                                            
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='form-control-lg'>Mpesa Number</label>
                                            <input 
                                                type='text'
                                                className='form-control'
                                                name='phone'
                                                value={values.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            { errors.phone && touched.phone && <p className='error'>{errors.phone}</p>}
                                        </div>
                                        <div className='col-md-4'>
                                            <label className='form-control-lg'>Amount</label>
                                            <input 
                                                type='number'
                                                min='1'
                                                className='form-control'
                                                name='amount'
                                                value={values.amount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            { errors.amount && touched.amount && <p className='error'>{errors.amount}</p>}
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <br/>
                            <div className="form-group">
                                <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakePayments;