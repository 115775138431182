import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from 'formik';
import { setMember } from "../Slices/auth";
import { useNavigate } from "react-router-dom";
import {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import axios from "axios";
import Toast from "./Toaster";
import toast from 'react-hot-toast'
import { memberRegisterSchema } from "../schemas";
import { logoutUser } from "../Slices/auth";
import mode from "./Mode";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MemberRegister = () =>{

    //authorization
    const user = useSelector((state)=>state.auth.user);
    const token = useSelector((state)=>state.auth.token);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/': 'http://127.0.0.1:8000/general/';

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }
    const dispatch = useDispatch()

    useEffect(()=>{
        if (user == null){
            toast.error('Unauthorized, login', {
                duration: 5000,
                position: 'bottom-left'
            })
            navigate('/login', {state: {reason: 'Unauthorized'}})
        }
    }, [])

    const onSubmit = ()=>{
        setIsLoading(true)
        axios.post(baseurl + 'add-member/'+ user.id +"/", {
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: (values.phone_number).slice(1, 10),
            course: values.course,
            baptised: Number(values.baptised),
            next_of_kin: values.next_of_kin,
            home_church: values.home_church,
            intake: Number(values.intake),
            gender: values.gender,
            reg_no: values.reg_no,
            report_status: 0
        }, config).then((response)=>{
            dispatch(setMember(response.data))
            //setIsLoading(false);
            toast.success('Successful Registration', {
                duration: 3000,
                position: 'bottom-left'
            })
            setTimeout(()=>{navigate('/dashboard')}, 3000)
            
        }).catch((err)=>{
            console.log(err);
            if (err.response.data.Error){
                toast.error(err.response.data.Error)
            }else{
                toast.error('An Error occurred')
                if(err.response.status == 401){
                    toast.error('Unauthorized, kindly login', {
                        position: 'bottom-left',
                        duration: 7000
                    })
                    dispatch(logoutUser())
                    navigate('/login')
                }
            }
            setIsLoading(false)
        })
    }

    const {values, errors,  handleBlur, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            course: '',
            next_of_kin: '',
            home_church: '',
            baptised: '',
            phone_number: '',
            intake: '',
            gender: '',
            reg_no: ''
        },
        validationSchema: memberRegisterSchema,
        onSubmit
    })

    return(
        <>
            <Toast/>
            <br/>
            <br/>
            <div className='container content-section'>
            <div className="row d-flex flex-column align-items-center justify-content-center m-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                <div className="content-section content col-md-9 m-auto" style={{backgroundColor: "white"}}>
                    <form method="POST" className="forms">
                        <fieldset>
                            <legend className="mb-4" style={{color:'#228bdf'}}>Member Details</legend>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <br/>
                                        <TextField
                                            label="First Name"
                                            fullWidth
                                            required
                                            error={errors.first_name && touched.first_name}
                                            id="outlined-size-small"
                                            size="small"
                                            name='first_name'
                                            variant="standard"
                                            value = {values.first_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.first_name && touched.first_name && <p className='error'>{errors.first_name}</p>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <br/>
                                        <TextField
                                            label="Last Name"
                                            fullWidth
                                            required
                                            error={errors.last_name && touched.last_name}
                                            id="outlined-size-small"
                                            variant="standard"
                                            size="small"
                                            name='last_name'
                                            value = {values.last_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.last_name && touched.last_name && <p className='error'>{errors.last_name}</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    <div className="form-group col-md-3">
                                        <br/>
                                        <TextField
                                            label="Phone Number (Active)"
                                            fullWidth
                                            error={errors.phone_number && touched.phone_number}
                                            required
                                            id="outlined-size-small"
                                            variant="standard"
                                            size="small"
                                            name='phone_number'
                                            value = {values.phone_number}
                                            onChange={(e) => {
                                                const trimmedPhone = e.target.value.trim();
                                                handleChange({
                                                  target: {
                                                    name: 'phone_number',
                                                    value: trimmedPhone,
                                                  },
                                                });
                                              }}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.phone_number && touched.phone_number && <p className='error'>{errors.phone_number}</p>}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <br/>
                                        <FormControl fullWidth error={errors.gender && touched.gender} required variant="standard">
                                            <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name='gender'
                                                value = {values.gender}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="M">MALE</MenuItem>
                                                <MenuItem value="F">FEMALE</MenuItem>
                                            </Select>
                                        </FormControl>
                                        { errors.gender && touched.gender && <p className='error'>{errors.gender}</p>}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <br/>
                                        <FormControl fullWidth error={errors.intake && touched.intake} required variant="standard">
                                            <InputLabel id="demo-simple-select-standard-label">Intake</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name='intake'
                                                value = {values.intake}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="2023">2023</MenuItem>
                                                <MenuItem value="2022">2022</MenuItem>
                                                <MenuItem value="2021">2021</MenuItem>
                                                <MenuItem value="2020">2020</MenuItem>
                                                <MenuItem value="2019">2019</MenuItem>
                                                <MenuItem value="2018">2018</MenuItem>
                                                <MenuItem value="2017">2017</MenuItem>
                                                <MenuItem value="0000">Associate</MenuItem>
                                            </Select>
                                        </FormControl>
                                            { errors.intake && touched.intake && <p className='error'>{errors.intake}</p>}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <br/>
                                        <FormControl fullWidth error={errors.baptised && touched.baptised} required variant="standard">
                                            <InputLabel id="demo-simple-select-standard-label">Baptised</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name = 'baptised'
                                                value = {values.baptised}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="1">YES</MenuItem>
                                                <MenuItem value="0">NO</MenuItem>
                                            </Select>
                                        </FormControl>
                                            { errors.baptised && touched.baptised && <p className='error'>{errors.baptised}</p>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <br/>
                                        <TextField
                                            label="Course"
                                            fullWidth
                                            error={errors.course && touched.course}
                                            required
                                            id="outlined-size-small"
                                            variant="standard"
                                            size="small"
                                            name = 'course'
                                            value = {values.course}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.course && touched.course && <p className='error'>{errors.course}</p>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <br/>
                                        <TextField
                                            label="School Reg Number"
                                            fullWidth
                                            error={errors.reg_no && touched.reg_no}
                                            required
                                            id="outlined-size-small"
                                            variant="standard"
                                            size="small"
                                            name = 'reg_no'
                                            value = {values.reg_no}
                                            onChange={(e) => {
                                                const trimmedReg = e.target.value.trim();
                                                handleChange({
                                                  target: {
                                                    name: 'reg_no',
                                                    value: trimmedReg,
                                                  },
                                                });
                                              }}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.reg_no && touched.reg_no && <p className='error'>{errors.reg_no}</p>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <br/>
                                        <TextField
                                            label="Next of Kin Phone No."
                                            fullWidth
                                            error={errors.next_of_kin && touched.next_of_kin}
                                            required
                                            id="outlined-size-small"
                                            variant="standard"
                                            size="small"
                                            name = 'next_of_kin'
                                            value = {values.next_of_kin}
                                            onChange={(e) => {
                                                const trimmedkinPhone = e.target.value.trim(); //remove trailing white 
                                                handleChange({
                                                  target: {
                                                    name: 'next_of_kin',
                                                    value: trimmedkinPhone,
                                                  },
                                                });
                                              }}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.next_of_kin && touched.next_of_kin && <p className='error'>{errors.next_of_kin}</p>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <br/>
                                        <TextField
                                            label="Home Church"
                                            fullWidth
                                            error={errors.home_church && touched.home_church}
                                            required
                                            id="outlined-size-small"
                                            variant="standard"
                                            size="small"
                                            name = 'home_church'
                                            value = {values.home_church}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            />
                                            { errors.home_church && touched.home_church && <p className='error'>{errors.home_church}</p>}
                                    </div>
                                </div>                                
                                
                        </fieldset>
                        <div className="form-group pt-3">
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
            <br/>
        </div>
        </>
    )
}

export default MemberRegister