import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Overlay from '../../overlay';
import Toast from '../../Toaster';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { CreateTicketSchema } from '../../../schemas';
import { logoutUser } from '../../../Slices/auth';
import { useNavigate } from 'react-router-dom';
import mode from '../../Mode';

const CreateTicket = () =>{
    const navigate = useNavigate()
    const userType = useSelector((state)=>state.auth.userType)
    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState('')
    const token = useSelector((state)=>state.auth.token)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/tickets/': 'http://127.0.0.1:8000/tickets/';
    const dispatch = useDispatch()

    useEffect(()=>{
        if (!userType.includes('Super Admin')){
            navigate('/dashboard')
        }
    })

    const config = {
        headers: {
            'Content-Type':'multpart/form-data'
        }
    }

    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    const onSubmit = () =>{
        const formData = new FormData()
        formData.append('display_pic', file)
        formData.append('name', values.name)
        formData.append('contact_phone', values.contact_phone)
        formData.append('manager', values.manager)
        setIsLoading(true)
        axios.post(baseurl+'tickets/', formData, config).then((resp)=>{
            //console.log(resp)
            setIsLoading(false)
            toast.success('Successfully created ticket', {
                position: 'bottom-left',
                duration: 5000 
            })
        }).catch((err)=>{
            console.log(err)
            if(err.response.status == 401){
                toast.error('Unauthorized, kindly login', {
                    position: 'bottom-left',
                    duration: 7000
                })
                dispatch(logoutUser())
                navigate('/login')
            }
            toast.error(err.response.data.Msg?'User manager does not exist': 'An error occurred', {
                position: 'top-center',
                duration: 5000
            })
            setIsLoading(false)

        })

    }

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            name: '',
            contact_phone: '',
            manager: '',
        },
        validationSchema: CreateTicketSchema,
        onSubmit
    })
    return(
        <div className='App'>
            <Overlay/>
            <Toast/>
            <div className='main-content'>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className='container content-section'>
                <div className="row d-flex flex-column align-items-center justify-content-center m-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                    <div className="content-section content col-md-7 m-auto" style={{backgroundColor: "white"}}>
                        <form method="POST" className='forms'>
                            <fieldset>
                                <legend className="mb-4" style={{color:'#228bdf'}}>Create Ticket</legend>                              
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputEmail4" className='form-control-lg'>Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name='name'
                                                value = {values.name}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                placeholder="Ticket Name"
                                                style = {errors.name && touched.name ? {borderColor:"#fc8181"}:{}}/>
                                                { errors.name && touched.name && <p className='error'>{errors.name}</p>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputPassword4" className='form-control-lg'>Display Pic</label>
                                            <input 
                                                type = 'file'
                                                required
                                                className="form-control"
                                                name = 'res'
                                                onChange={(e)=>setFile(e.target.files[0])}/>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label htmlFor="inputEmail4" className='form-control-lg'>Manager</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name = 'manager'
                                                value={values.manager}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                placeholder="Manager Username"
                                                style = {errors.manager && touched.manager ? {borderColor:"#fc8181"}:{}}/>
                                                { errors.manager && touched.manager && <p className='error'>{errors.manager}</p>}

                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor="inputEmail4" className='form-control-lg'>Contact Phone</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name = 'contact_phone'
                                                value={values.contact_phone}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                placeholder="Contact Phone"
                                                style = {errors.contact_phone && touched.contact_phone ? {borderColor:"#fc8181"}:{}}/>
                                                { errors.contact_phone && touched.contact_phone && <p className='error'>{errors.contact_phone}</p>}
                                        </div>
                                        
                                    </div>
                                    
                            </fieldset>
                            <div className="form-group" style={{margin: '6px'}}>
                                <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Create"}</button>
                            </div>
                        </form>
                    </div>
            </div>
                </div>
            </div>
        </div>
    )
}

export default CreateTicket