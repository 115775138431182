import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link } from "react-router-dom";
import {FaHouseUser} from 'react-icons/fa'
import {BsFillTelephoneOutboundFill} from 'react-icons/bs'

function Test() {
  const { collapseSidebar } = useProSidebar();
  return (
    <div id="app" style={{background:'yellow'}}>
      <Sidebar defaultCollapsed={true} style={{position: 'fixed'}} className="sideBarContainer">
        <Menu>
          <MenuItem
            icon={<MenuOutlinedIcon />}
            onClick={() => {
              collapseSidebar();
            }}
            style={{ textAlign: "center" }}
          >
            {" "}
            <h2>Admin</h2>
          </MenuItem>

          <MenuItem icon={<HomeOutlinedIcon />}>Home</MenuItem>
          <MenuItem icon={<PeopleOutlinedIcon />}>Team</MenuItem>
          <MenuItem icon={<ContactsOutlinedIcon />}>Contacts</MenuItem>
          <MenuItem icon={<ReceiptOutlinedIcon />}>Profile</MenuItem>
          <MenuItem icon={<HelpOutlineOutlinedIcon />}>FAQ</MenuItem>
          <MenuItem icon={<CalendarTodayOutlinedIcon />}>Calendar</MenuItem>
        </Menu>
      </Sidebar>
      <div className="main-content" style={{background: 'blue'}}>
        <div className="row mx-auto container justify-content-center" style={{background:'red', width: '100%'}}>
            <Link to='/all-families' className="card text-white mb-2 col-4" style={{backgroundColor: '#ffffff'}}>
                <div className="card-body">
                    <div className="row justify-content-center align-items-center" style={{color: '#565656f4'}}>
                        <div className="col-md-5" style={{fontSize: '3em', color: '#228bdf'}}>
                            <FaHouseUser/>
                        </div>
                        <div className='col-md-5'>
                            <h6>FAMILIES</h6>
                        </div>

                    </div>
                </div>
            </Link>
                        <Link to='/my-family' className="card text-white mb-2 col-md-4" style={{backgroundColor: '#ffffff'}}>
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center" style={{color: '#565656f4'}}>
                                    <div className="col-md-5" style={{fontSize: '3em', color: '#228bdf'}}>
                                        <FaHouseUser/>
                                    </div>
                                    <div className='col-md-5'>
                                        <h6>MY FAMILY</h6>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        <Link to='/contact-us' className="card text-white mb-2 col-md-4" style={{backgroundColor: '#ffffff'}}>
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center" style={{color: '#565656f4'}}>
                                    <div className="col-md-5" style={{fontSize: '3em', color: '#228bdf'}}>
                                        <BsFillTelephoneOutboundFill/>
                                    </div>
                                    <div className='col-md-5'>
                                        <h6>CONTACT NURTURING</h6>
                                    </div>
                                </div>
                            </div> 
                        </Link>
        </div>
      </div>
    </div>
  );
}

export default Test;