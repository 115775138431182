import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import toast from 'react-hot-toast'
import ReactLoading from 'react-loading'
import Overlay from '../overlay'
import Toast from '../Toaster'
import { logoutUser } from '../../Slices/auth'
import { useNavigate } from 'react-router-dom'
import mode from '../Mode'

const UploadResources = ()=>{
    const [isLoading, setIsLoading] = useState(false)
    const [fileName, setFileName] = useState('')
    const [resName, setResName] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const [resources, setResources] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/': 'http://127.0.0.1:8000/';
    const token = useSelector((state)=>state.auth.token)
    const user = useSelector((state)=>state.auth.user)
    const userType = useSelector((state)=>state.auth.userType)
    const config = {
        headers: {
            'Content-Type':'multpart/form-data'
        }
    }
    const genConfig = {
        headers: {
            'Content-Type':'application/json'
        }
    }

    const downloadConfig = {
        headers: {
            'Content-Type':'application/pdf'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
        genConfig.headers['Authorization'] = `Token ${token}`;
        downloadConfig.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        if (!(userType.includes('Super Admin') || userType.includes('Nurturing Admin'))){
            navigate('/dashboard')
        }
        axios.get(baseurl+'general/resources/', config).then((res)=>{
            setResources(res.data)
            setIsLoaded(true)
        }).catch((err)=>{
            console.log(err)
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            setIsLoaded(true)
        })
    }, [])

    const handleSubmit = (e) =>{
        e.preventDefault()
        setIsLoading(true)
        let formData = new FormData()
        formData.append("file", fileName)
        formData.append("name", resName)
        formData.append("owner_id", user.id)
        
        axios.post(baseurl+'/general/resources/', formData, config).then((res)=>{
            console.log(res)
            setResources([...resources, res.data])
            setIsLoading(false)
            toast.success('Resource successfully uploaded', {
                position: 'bottom-left',
                duration: 5000
            })
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
            toast.success('An error occurred', {
                position: 'bottom-left',
                duration: 5000
            })
        })

    }

    const deleteResource = (index)=>{
        axios.post(baseurl+'general/resources/delete', {id: resources[index].id}, genConfig).then((res)=>{
            console.log(res)
            let tempRes = [...resources]
            let removedRes = tempRes.splice(index, 1)
            setResources(tempRes)
            toast.success('Resource deleted', {
                position: 'bottom-left',
                duration: 5000
            })
        }).catch((err)=>{
            console.log(err)
            if(err.response.status == 401){
                toast.error('Unauthorized, kindly login', {
                    position: 'bottom-left',
                    duration: 7000
                })
                dispatch(logoutUser())
                navigate('/login')
            }
            toast.error('An error occurred', {
                position: 'bottom-left',
                duration: 5000
            })
        })
    }

    const downloadResource = (url,name)=>{
        axios.get(baseurl + url,{
            responseType: 'blob' // Set the response type to 'blob'
          }, downloadConfig).then((response)=>{
            // Create a temporary URL for the downloaded file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            console.log(response.data)

            // Create a link element and initiate the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name+'.pdf');
            document.body.appendChild(link);
            link.click();

            // Clean up the temporary URL
            window.URL.revokeObjectURL(url);
        })
    }

    return(
        <div id='App'>
            <Toast/>
            <Overlay/>
            <div className='main-content'>
                <br/>
                <br/>
                <div className='mx-auto card mb-1 rolesBody content'>
                    <h3>Upload Resources</h3>
                    <form className="forms row mx-auto" style={{width: '100%'}} onSubmit={handleSubmit}>
                            <div className='form-group col-md-4 mx-auto'>
                                <label>Res Name</label>
                                <input
                                    type='text'
                                    required
                                    className='form-control'
                                    value = {resName}
                                    name='resName'
                                    placeholder='Name of the Resource'
                                    onChange={(e)=>setResName(e.target.value)}

                                />
                            </div>
                            <div className='form-group col-md-4 mx-auto'>
                                <label>File</label>
                                <input
                                    type = 'file'
                                    required
                                    className="form-control"
                                    name = 'res'
                                    onChange={(e)=>setFileName(e.target.files[0])}
                                    
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <br/>
                                <button type='submit' className="btn btn-outline-info" disabled={isLoading} style={{borderRadius: '0.5rem'}}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Upload"}</button>
                            </div>
                    </form>
                    <br/>
                </div>
                <div>
                        <br/>
                        <h4 className='display-6'>Resources</h4>
                        <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                            <thead>
                                <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                    <td>Name</td>
                                    <td>By</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    resources.map((resource, index)=>{
                                        return(
                                            <tr>
                                                <td>{resource.name}</td>
                                                <td>{resource.owner.username}</td>
                                                <td><button className='btn btn-sm btn-primary' onClick={()=>downloadResource(resource.file, resource.name)}>Download</button></td>
                                                <td><button className='btn btn-sm btn-danger' onClick={()=>deleteResource(index)}>Delete</button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                     </div>
            </div>
        </div>
    )
}
export default UploadResources