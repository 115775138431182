import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Overlay from "./overlay";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import WorkIcon from '@mui/icons-material/Work';
import TimelineIcon from '@mui/icons-material/Timeline';
import SavingsIcon from '@mui/icons-material/Savings';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import { logoutUser, setUserType } from "../Slices/auth";
import AuthLoading from "./AuthLoading";
import CollectionsIcon from '@mui/icons-material/Collections';


const Dashboard = () => {

    const dispatch = useDispatch()
    const user = useSelector((state)=>state.auth.user);
    const member = useSelector((state)=>state.auth.member);
    const [names, setNames] = useState([])
    const userType = useSelector((state)=>state.auth.userType);
    const [loaded, setIsLoaded] = useState(false)
    const navigate = useNavigate()


    useEffect(()=>{
       
        if (user == null){
            dispatch(logoutUser())
            navigate('/login', {state: {reason: 'Unauthorized'}})
            
        }
        if (member){
            var member_names = member.member.name.split(" ")
            setNames(member_names)
        }
        setIsLoaded(true)
    }, [])

    return (
            <div className="App">
                <Overlay/>
                <br/>
                {
                    (!loaded) ? <AuthLoading/>:
                    <div className="dashboardBody">
                        <div className="mb-12" style={{width: '100%'}}>
                            <h6 className="mb-12 display-6" style={{textAlign: 'left'}}>Welcome {names.length > 0 ? names[0]: null}</h6>
                        </div>
                        <Link to='/report-session' className="card mb-4 content dashboardItem">
                                <div className="card-body">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <AssignmentReturnedIcon sx={{fontSize: 60}}/>
                                        </div>
                                        <div className='col-md-8'>
                                            <p>REPORT SESSION</p>
                                        </div>
                                    </div>
                                </div> 
                        </Link>
                        <Link to='/gallery' className="card mb-4 content dashboardItem">
                                <div className="card-body">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <CollectionsIcon sx={{fontSize: 60}}/>
                                        </div>
                                        <div className='col-md-8'>
                                            <p>GALLERY</p>
                                        </div>
                                    </div>
                                </div> 
                        </Link>
                        <Link to='/resources' className="card mb-4 content dashboardItem">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <InsertDriveFileIcon sx={{fontSize: 60}}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <p>RESOURCES</p>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        <Link to='/tickets' className="card mb-4 content dashboardItem">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <ConfirmationNumberIcon sx={{fontSize: 60}}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <p>TICKETS</p>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        {
                            userType.includes('Super Admin') ? <>
                                <Link to='/admin-general-roless' className="card mb-4 content dashboardItem">
                                    <div className="card-body">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                                <WorkIcon sx={{fontSize: 60}}/>
                                            </div>
                                            <div className='col-md-8'>
                                                <p>ROLES</p>
                                            </div>
                                        </div>
                                    </div> 
                                </Link>
                            </>:null
                        }
                        <div className="mb-12" style={{width: '100%'}}>
                            <h6 className="mb-12 display-6" style={{textAlign: 'center'}}>Nurturing <FamilyRestroomIcon sx={{fontSize: 50}}/></h6>
                        </div>
                        <Link to='/all-families' className="card mb-4 content dashboardItem">
                                <div className="card-body">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                            <Diversity3Icon sx={{fontSize: 60}}/>
                                        </div>
                                        <div className='col-md-8'>
                                            <p>FAMILIES</p>
                                        </div>

                                    </div>
                                </div>
                        </Link>
                        <Link to='/my-family' className="card mb-4 content dashboardItem">
                                <div className="card-body">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                            <Diversity1Icon sx={{fontSize: 60}}/>
                                        </div>
                                        <div className='col-md-8'>
                                            <p>MY FAMILY</p>
                                        </div>
                                    </div>
                                </div> 
                        </Link>
                        
                        <Link to='/contact-us' className="card mb-4 content dashboardItem">
                                <div className="card-body">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <ConnectWithoutContactIcon sx={{fontSize: 60}}/>
                                        </div>
                                        <div className='col-md-8'>
                                            <p>CONTACT US</p>
                                        </div>
                                    </div>
                                </div> 
                        </Link>
                        {
                            (userType.includes("Nurturing Admin") || userType.includes("Super Admin")) ? <>
                                <Link to='/admin-generate-families' className="card mb-4 content dashboardItem">
                                    <div className="card-body">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                                <Diversity2Icon sx={{fontSize: 60}}/>
                                            </div>
                                            <div className='col-md-8'>
                                                <p>GENERATE FAMILIES</p>
                                            </div>
                                        </div>
                                    </div> 
                                </Link>
                                <Link to='/admin-filter-parents' className="card mb-4 content dashboardItem">
                                    <div className="card-body">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                                <FilterAltIcon sx={{fontSize: 60}}/>
                                            </div>
                                            <div className='col-md-8'>
                                                <p>FILTER PARENTS</p>
                                            </div>
                                        </div>
                                    </div> 
                                </Link>
                            </>:null
                        }

                        <div className="mb-12" style={{width: '100%'}}>
                            <h6 className="mb-12 display-6" style={{textAlign: 'center'}}>Treasury <SavingsIcon sx={{fontSize: 50}}/></h6>
                        </div>
                        <Link to='/make-payments' className="card mb-4 content dashboardItem">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <SavingsIcon sx={{fontSize: 60}}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <p>Make Payments</p>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        <Link to='/my-contributions' className="card mb-4 content dashboardItem">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <TimelineIcon sx={{fontSize: 60}}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <p>My Contributions</p>
                                    </div>
                                </div>
                            </div> 
                        </Link>

                        <div className="mb-12" style={{width: '100%'}}>
                            <h6 className="mb-12 display-6" style={{textAlign: 'center'}}>Publishing <LocalLibraryIcon sx={{fontSize: 50}}/></h6>
                        </div>
                        <Link to='/publishing' className="card mb-4 content dashboardItem">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <MenuBookIcon sx={{fontSize: 60}}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <p>View Books</p>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        {
                            (userType.includes("Publishing Admin") || userType.includes("Super Admin")) ? <>
                                <Link to='/books-manager' className="card mb-4 content dashboardItem">
                                    <div className="card-body">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                                <PeopleIcon sx={{fontSize: 60}}/>
                                            </div>
                                            <div className='col-md-8'>
                                                <p>Manage Books</p>
                                            </div>
                                        </div>
                                    </div> 
                                </Link>
                            </>:null
                        }

                        <div className="mb-12" style={{width: '100%'}}>
                            <h6 className="mb-12 display-6" style={{textAlign: 'center'}}>Sabbath School <LocalLibraryIcon sx={{fontSize: 50}}/></h6>
                        </div>
                        <Link to='/buy-lesson' className="card mb-4 content dashboardItem">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                        <MenuBookIcon sx={{fontSize: 60}}/>
                                    </div>
                                    <div className='col-md-8'>
                                        <p>Buy Lesson</p>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        {
                            (userType.includes("Sabbath School Admin") || userType.includes("Super Admin")) ? <>
                                <Link to='/lesson-subscribers' className="card mb-4 content dashboardItem">
                                    <div className="card-body">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-3" style={{fontSize: '2.7em', color: '#228bdf'}}>
                                                <PeopleIcon sx={{fontSize: 60}}/>
                                            </div>
                                            <div className='col-md-8'>
                                                <p>Lesson Subscribers</p>
                                            </div>
                                        </div>
                                    </div> 
                                </Link>
                            </>:null
                        }

                    </div>
                }
            </div>
    )
}

export default Dashboard