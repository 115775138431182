import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import Overlay from '../overlay';
import { PurchaseTIcketSchema } from '../../schemas';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading'
import mode from '../Mode';
import Toast from '../Toaster';
import { toast } from 'react-hot-toast';
import Button from '@mui/material/Button';

const Tickets = () =>{
    const [tickets, setTickets] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(null)
    const [sentPush, setSentPush] = useState(false)
    const [ticketNumber, setTicketNumber] = useState('')
    const [checkedTransaction, setCheckedTransaction] = useState(false)
    const [transactionStatus, setTransactionStatus] = useState(1)
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/': 'http://127.0.0.1:8000/';
    const member = useSelector((state)=>state.auth.member)
    const isAuthenticated = useSelector((state)=>state.auth.isAuthenticated)
    //const user = useSelector((state)=>state.auth.user)
    // const names = (member.member.name).split(" ")
    //
    // const token = useSelector((state)=>state.auth.token)
    // const config = {
    //     headers: {
    //         'Content-Type':'multpart/form-data'
    //     }
    // }

    // if(token){
    //     config.headers['Authorization'] = `Token ${token}`;
    // }

    useEffect(()=>{
        axios.get(baseurl+'tickets/tickets/').then((resp)=>{
            //console.log(resp.data)
            setTickets(resp.data)
            setIsLoaded(true)
        }).catch((err)=>{
            console.error(err)
        })
    }, [])

    const goToManage = (ticket) =>{
        navigate('/manage-ticket', {ticket: ticket})
    }

    const onSubmit = () =>{
        //sending the stk push request
        setIsLoading(true)
        const phoneNu = values.phone
        const updated_num = "+254" + phoneNu[1] + phoneNu[2]+ phoneNu[3]+ phoneNu[4]+ phoneNu[5]+ phoneNu[6]+ phoneNu[7]+ phoneNu[8]+ phoneNu[9]
        console.log(selectedTicket.options)
        console.log(values.option)
        console.log(Number(selectedTicket.options[values.ticket_type]))
        axios.post(baseurl+'payments/checkout/', {
            phone_number: updated_num,
            amount:  Number(selectedTicket.options[values.ticket_type].price),
            reference: selectedTicket.name,
            description: selectedTicket.name,
            billref: selectedTicket.name
        }).then((resp)=>{
            //console.log(resp)
            setSentPush(true)
            //to check and confirm message then save the ticket
            delay(resp)
        }).catch((err)=>{
            console.error(err)
            //to show the error to the buyer
        })
    }

    const delay = (r) => {
        setTimeout(()=>{
            checkPaymentStatus(r.data.billref)
        }, 32000)
    }

    const emailConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + process.env.REACT_APP_EMAIL_TOKEN + '==' //remember == is part of the current token
        }
    }

    const checkPaymentStatus = (id) => {
        axios.get( baseurl + 'payments/check-transaction/'+id).then((response) => {
            setTransactionStatus(response.data.status)
            if(!response.data.status){
                axios.post(baseurl + 'tickets/attendees/', {
                    ticket: selectedTicket.id,
                    first_name: values.first_name,
                    middle_name: values.middle_name,
                    last_name: values.last_name,
                    email: values.email,
                    phone_number: values.phone,
                    ticket_type: selectedTicket.options[values.ticket_type].name,
                }).then((resp)=>{
                    //console.log(resp)
                    setTicketNumber(resp.data.ticket_number)
                    setCheckedTransaction(true)
                    //send ticket to email. Via the backend
                    axios.post('https://mailer.jkusdachurch.org/send/transactional', [
                        {
                            "senderFrom": {
                                "name": "JKUSDA Portal",
                                "email": "info@jkusdachurch.org"
                            },
                            "replyTo": {
                                "name": "JKUSDA Portal",
                                "email": "info@jkusdachurch.org"
                            },
                            "sendTo": [
                                {
                                    "name": member.member.name,
                                    "email": values.email
                                }
                            ],
                            "subject": `${selectedTicket.name} Ticket`,
                            "textContent": "Ticket",
                            "htmlContent": `<!DOCTYPE><html><body><p><div>Thank you for buying a ticket. Your ticket Number: <br/>${resp.data.ticket_number}</div></p></body></html>`,
                            "attachments": []
                        }
                    ], emailConfig).then((emailResp)=>{
                        console.log(emailResp)
                    }).catch((emailErr)=>{
                        console.error(emailErr)
                    })
                    setIsLoading(false)
                }).catch((err)=>{
                    console.error(err)
                    setIsLoading(false)
                })
            }else{
                setIsLoading(false)
                setCheckedTransaction(true)
                //console.log(response)
                toast.error('Transaction incomplete')
            }
        }).catch((error)=>{
            console.error(error)
            toast.error('An error occurred, please contact JKUSDA Treasury')
        })
    }

    const resetAndToggle = (index) => {
        setSentPush(false)
        setTicketNumber('')
        setCheckedTransaction(false)
        setTransactionStatus(1)
        values.ticket_type = ''
        setSelectedTicket(tickets[index])
        console.log(tickets[index])
    }

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues:{
            first_name: "",
            middle_name: '',
            last_name: "",
            email: "",
            phone: "",
            ticket_type: ''
        },
        validationSchema: PurchaseTIcketSchema,
        onSubmit

    })

    return(
        <div className='App'>
            {isAuthenticated ? <Overlay/>: null}
            <div className='main-content'>
                <div className='container'>
                    <br/>
                    <h4 className='display-6'>JKUSDA Tickets</h4>
                    {
                        isLoaded ? tickets.length < 1 ? <h3 className='display-2'>No Tickets Currently</h3>:
                        <div className='row row-cols-1 row-cols-md-2 g-4' style={{marginBottom: "15px"}}>
                        {
                                tickets.map((ticket, index)=>{
                                    return(
                                        <div className="col text-center" key={index}>
                                            <div className="card" style={{border:'none'}} style={{padding: '0'}}>
                                                <img src={baseurl+ticket.display_pic} className="card-img-top" style={{maxHeight: '500px'}} alt="Ticket Pic"/>
                                                <div className="card-body">
                                                    <h5 className="card-title">{ticket.name}</h5>
                                                    <Button variant="contained" onClick={()=>resetAndToggle(index)} data-bs-toggle="modal" data-bs-target="#buyModalCenter">Purchase</Button>
                                                    {
                                                        isAuthenticated ? ticket.manager == member.user.id ? <>
                                                            <br/>
                                                            <Link className="btn btn-primary" style={{background:'#228bdf', margin: '10px'}} to={'/manage-ticket'} state={ticket}>Manage</Link>
                                                            <button className="btn btn-danger" style={{margin: '10px'}} >Delete</button>
                                                        </>:null: null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }

                    </div>: <Loading/>
                    }

                </div>
            </div>

            <div className="modal fade" id="buyModalCenter" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="buyModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle" style={{color: '#29b8b0'}}>{selectedTicket !=null ? selectedTicket.name: ''}</h5>
                        <button type="button" disabled={isLoading} className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className='forms'>
                            <legend style={{color: '#29b8b0'}}>Buy Ticket</legend>
                            <p style={{color: '#228bdf'}}><b>Confirm Details before purchasing</b></p>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>First Name</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name = 'first_name'
                                        value = {values.first_name}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.first_name ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.first_name && <p className='error'>{errors.first_name}</p>}
                                </div>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Middle Name</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name = 'middle_name'
                                        value = {values.middle_name}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.middle_name ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.middle_name && <p className='error'>{errors.middle_name}</p>}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Last Name</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name = 'last_name'
                                        value = {values.last_name}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.last_name ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.last_name && <p className='error'>{errors.last_name}</p>}
                                </div>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Email</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name = 'email'
                                        value = {values.email}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.email ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.email && <p className='error'>{errors.email}</p>}

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Mpesa Number</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name = 'phone'
                                        value = {values.phone}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.phone ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.phone && <p className='error'>{errors.phone}</p>}
                                </div>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Option</label>
                                    <select
                                        type='text'
                                        className='form-control'
                                        name = 'ticket_type'
                                        value = {values.ticket_type}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.ticket_type ? {borderColor:"#fc8181"}:{}}>
                                            <option></option>
                                            {
                                                selectedTicket ?
                                                selectedTicket.options.map((option, key)=>{
                                                    return(<>
                                                        <option value={key} key={key}>{option.name}</option>
                                                    </>)
                                                }):null
                                            }

                                        </select>
                                        { errors.ticket_type && <p className='error'>{errors.ticket_type}</p>}
                                </div>
                            </div>
                        </form>
                        {
                            sentPush ?
                            <div>
                                <br/>
                                <p style={{color:'green', fontSize: '1.13em'}}>
                                    An Mpesa push prompt has been sent to your phone. Kindly complete transaction and wait for the ticket number. Don't exit this page
                                </p>
                                <br/>
                                {
                                    checkedTransaction ? (!transactionStatus) ?
                                    <div>
                                        <b style={{color:'green', fontSize: '1.13em'}}>Transaction Complete. Ticket number:</b>
                                        <h2 style={{color:'greenYellow', fontSize: '2em'}}><i>{ticketNumber}</i></h2>

                                    </div>:
                                    <div>
                                        <b style={{color:'red', fontSize: '1.13em'}}>Transaction Incomplete</b>
                                    </div>:null
                                }


                            </div> : null
                        }

                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={isLoading} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Purchase"}</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default Tickets
