import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const HomeNav = () =>{
  const [isOpen, setIsOpen] = useState(false)

    return (
        <header className="site-header">
            <nav className="navbar navbar-expand-md navbar-dark fixed-top" style={{backgroundColor: '#228bdf', padding: '15px'}}>
              <div className="container">
                <a className="navbar-brand mr-4" href="#" style={{fontSize: '1.5em'}}>JKUSDA</a>
                <button className="navbar-toggler" type="button" onClick={()=>setIsOpen(!isOpen)} data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
                  {isOpen ? <CloseIcon sx={{fontSize:30, color:'white'}}/>:<span className="navbar-toggler-icon"></span>}
                </button>
                <div className="collapse navbar-collapse" id="navbarToggle" style={{color: 'white'}}>
                  <div className="navbar-nav mr-auto">
                      <Link to='/'className="navbar-brand mr-4 display-6">Home</Link>
                  </div>

                  <div className="navbar-nav ms-auto" style={{textAlign: 'right'}}>
                    <div className="nav-item">
                        <Link to='/Login' className="mr-4 nav-link" href="#">Login</Link>
                    </div>
                    <div className="nav-item">
                        <Link to='/Register' className="mr-4 nav-link" href="#">Register</Link>
                    </div>
                    <div className="nav-item">
                        <Link to='/tickets' className="navbar-brand  mr-4 nav-link" href="/tickets">Tickets</Link>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <br/>
            <br/>
        </header>
    )
}

export default HomeNav;
