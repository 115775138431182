import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import Overlay from '../overlay';
import Loading from '../loading';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../Slices/auth';
import mode from '../Mode';

const AllFamilies = () =>{
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/nurturing/': 'http://127.0.0.1:8000/nurturing/';
    const user = useSelector((state)=>state.auth.user)
    const token = useSelector((state)=>state.auth.token)
    const [data, setData] = useState({})
    const [familiesList, setFamiliesList] = useState([])
    const [indexList, setIndexList] = useState([])
    const [parentsList, setParentsList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [anError, setError] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        if (user == null){
            navigate('/login', {state: {reason: 'Unauthorized'}})
        }
        setIsLoading(true)
        axios.get(`${baseurl}all-families/`, config).then((resp)=>{
            //console.log(resp.data)
            setData(resp.data)
            setFamiliesList(resp.data.families_list)
            setIndexList(resp.data.indexList)
            setParentsList(resp.data.parents)
            setTimeout(()=>{setIsLoading(false)}, 2000)
        }).catch((err)=>{
            console.error(err)
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            setError(true)
            setTimeout(()=>{setIsLoading(false)}, 2000)
        })
    }, [])

    return(
        <div id='App'>
            <Overlay/>
            <div className="main-content">
                <h3 style={{color: '#228bdf'}} className='display-3'>Families</h3>
                {
                    isLoading ? <Loading/>: anError ? <div>
                        <br/>
                        <h1>Ooops! Snap. An Error Occured.</h1>
                    </div>
                    :                
                
                    familiesList.map((fm, index)=>{
                        return(
                            <>
                                <h4 className='display-6'>Family {fm}</h4>
                                <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                    <thead>
                                        <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                            <td>NAME</td>
                                            <td>GROUP</td>
                                            <td>NUMBER</td>
                                        </tr>
                                    </thead>
                                    <tbody >
                                    {
                                            parentsList.map((parent, index)=>{
                                                if(parent.number == fm){
                                                    return(
                                                        <>
                                                            {
                                                                parent.dad?
                                                                    <tr>
                                                                        <td><b>{`${parent.dad.member.name} - Dad`}</b></td>
                                                                        <td><b>{parent.dad.member.yos}</b></td>
                                                                        <td><b>{parent.dad.phone.member_phone}</b></td>
                                                                    </tr>
                                                                :null
                                                            }
                                                            {
                                                                parent.mom?
                                                                <tr>
                                                                    <td><b>{`${parent.mom.member.name} - Mom`}</b></td>
                                                                    <td><b>{parent.mom.member.yos}</b></td>
                                                                    <td><b>{parent.mom.phone.member_phone}</b></td>
                                                                </tr>
                                                                :null
                                                            }
                                                        </>
                                                        
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            indexList.map((i, index)=>{
                                                if(data.family[i] == fm){
                                                    return(
                                                        <tr>
                                                            <td>{data.name[i]}</td>
                                                            <td>{data.intake[i]}</td>
                                                            <td>{data.phone_number[i]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    
                                    </tbody>
                                </table>
                            </>
                        )
                    })
                }
            
                
            </div>
        </div>
    )
}

export default AllFamilies