import React, { useEffect } from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser, setToken, setUserType, setMember } from '../Slices/auth'
import ReactLoading from 'react-loading'
import axios from 'axios'
import toast from 'react-hot-toast'
import {Link} from 'react-router-dom'
import Toast from './Toaster'
import mode from './Mode';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';

const Login = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/' : 'http://127.0.0.1:8000/general/';
    const { state } = useLocation();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    useEffect(()=>{
        if (state){
            if(state.reason){
                toast.error('Unauthorized, kindly login', {
                    duration: 7000,
                    position: 'bottom-left'
                })
            }
        }
    }, [])

    const onSubmit = () =>{
        setIsLoading(true)
        axios.post(baseurl+'login/', {
            username: values.email,
            password: values.password
        }).then((response)=>{
            //setIsLoading(false);
            //console.log(response.data.member)
            dispatch(setUser(response.data.user));
            dispatch(setToken(response.data.token));
            dispatch(setUserType(response.data.userType));
            dispatch(setMember(response.data.member));
            if(response.data.member_exists){
                toast.success('Login Success');
                setTimeout(()=>{
                    if(response.data.userType.includes("Nurturing Admin")){
                        navigate('/admin-dashboard');
                    }else{
                        navigate('/dashboard');
                    }
                }, 5000)
            }else{
                toast.success('Success. Kindly Complete Registration', {
                    duration: 3000,
                    position: 'bottom-left'
                });
                setTimeout(()=>{navigate('/member-register')}, 5000)
            }
        }).catch((err)=>{
            setIsLoading(false);
            console.log('Error');
            console.log(err);
            toast.error('Invalid Details', {
                position: 'top-center',
                duration: 2000
            })
        })
    }

    const {values, errors,  handleBlur, touched, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            email: '',
            password:''
        },
        onSubmit
    })
    return(
        <>
        <div className='container'>
            <Toast/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="content-section row d-flex flex-column align-items-center justify-content-center my-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                <div className="content-section content col-md-5 my-auto" style={{backgroundColor: "white"}}>
               
                    <form method="POST" className='forms'>
                    
                        <fieldset className='mx-auto'>
                            <legend className="border-bottom mb-4" style={{color:'#228bdf'}}>Student Login</legend>
                                <div className='row justify-content-center'>
                                    <div className='col-md-9'>
                                        <TextField 
                                            fullWidth required 
                                            error={false}
                                            id="standard-basic" 
                                            label="Email" 
                                            variant="standard" 
                                            style={{marginLeft: '5px', borderColor: 'red'}}
                                            name = 'email'
                                            value = {values.email}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                        />
                                        <br/>
                                        <br/>
                                    </div>
                                    
                                    <div className='col-md-9'>
                                        <FormControl fullWidth sx={{ m: 1 }} variant="standard" required>
                                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                            <Input
                                                id="standard-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                name = 'password'
                                                value = {values.password}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </div>                          
                                
                        </fieldset>
                        <div className="form-group pt-3">
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Login"}</button>
                        </div>
                    </form>
                    <div className="pt-3">
                        <small className="text-muted">
                            Have No Account?<Link className="ml-2" to='/Register'>Sign Up </Link>
                            Forgot Password?<Link className="ml-2" to='/reset-give-email'>Reset</Link>
                        </small>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login