import React, {useState, useEffect} from 'react';
import { setToken } from '../Slices/auth';
import { useSelector } from 'react-redux';
import axios from 'axios';
import base64 from 'base-64';
import { useDispatch } from 'react-redux';
import mode from './Mode';

export const useTokenManager = (dummy) =>{
    const [jkToken, setJkTokenState] = useState(useSelector((state)=>state.auth.jk_token))
    const [token_time, setToken_time] = useState(new Date(useSelector((state)=>state.auth.jk_token_time)))
    const username = process.env.REACT_APP_API_USER_NAME
    const password = process.env.REACT_APP_API_PASSWORD
    const time_now = new Date()
    const time_before_exp = (time_now.getTime() - token_time.getTime() / 1000)
    const dispatch = useDispatch()
    const baseurl = mode ? 'https://api.jkusdachurch.org/' : 'https://sandbox.jkusdachurch.org/';

    //console.log('Here in the token manager')

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Basic " + base64.encode(username + ":" + password),
        }
    }

    const refresh_config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + jkToken,
        }
    }

    const auth_url = baseurl + 'auth/authenticate'
    const refresh_url = baseurl + '/auth/refresh'

    useEffect(()=>{
        if (jkToken == null){
            
            axios.get(auth_url, config).then((resp)=>{
                setJkTokenState(resp.data.data.api_key)
    
            }).catch((err)=>{
                console.error(err)
            })
        }else if(time_before_exp > 100 &&  time_before_exp < 3600){
            //refresh the token
           
            axios.get(refresh_url, refresh_config).then((resp)=>{
                
                setJkTokenState(resp.data.data)
    
            }).catch((err)=>{
                console.error(err)
            })
        }else if(time_before_exp >= 3600){
            //token expired, get a new token
           
            axios.get(auth_url, config).then((resp)=>{
                
                setJkTokenState(resp.data.data.api_key)
    
            }).catch((err)=>{
                console.error(err)
            })
        }
    }, [dummy])
    return jkToken;
}