import React from 'react'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    jk_token: null,
    jk_token_time: new Date(),
    user: null,
    isAuthenticated: false,
    member: null,
    userType: ['Normal'],
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true
        },
        setMember: (state, action) =>{
            state.member = action.payload
        },
        setToken: (state, action) =>{
            state.token = action.payload
        },
        setUserType: (state, action) =>{
            state.userType= action.payload
        },
        setJkToken: (state, action) =>{
            state.jk_token = action.payload
            state.jk_token_time = new Date()
        },
        logoutUser: (state) =>{
            state.user= null
            state.isAuthenticated = false
            state.token = null
            state.member = null
            state.userType = ['Normal']
            state.jk_token = null
            state.jk_token_time= new Date()
        },
    }
})

export const { setUser, setToken, logoutUser, setUserType, setMember, setJkToken } = authSlice.actions

export default authSlice.reducer



