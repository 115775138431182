import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Overlay from '../../overlay';
import Toast from '../../Toaster';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateTicketSchema } from '../../../schemas';
import ReactLoading from 'react-loading';
import {useLocation} from 'react-router-dom';
import { toast } from 'react-hot-toast';
import mode from '../../Mode';
import Loading from '../../loading';

const ManageTicket = () =>{
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [optionLoading, setOptionLoading] = useState(false)
    const [attendees, setAttendees] = useState([])
    const [options, setOptions] = useState([])
    const [price, setPrice] = useState('')
    const [name, setName] = useState('')
    const location = useLocation()
    const [ticket, setTicket] = useState(location.state)
    const [file, setFile] = useState('')
    const user = useSelector((state)=>state.auth.user)
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/': 'http://127.0.0.1:8000/';

    useEffect(()=>{
        if(ticket){
            if(user.id != ticket.manager){
                navigate('/tickets')
            }
            axios.get(baseurl+'tickets/check-ticket/'+ticket.ticket_id).then((resp)=>{
                //console.log(resp.data)
                setAttendees(resp.data.attendees)
                setTicket(resp.data.ticket)
                setOptions(resp.data.options)
                setIsLoaded(true)
            }).catch((err)=>{
                console.log(err)
                setIsLoaded(true)
                
            })
        }else{
            navigate('/tickets')
        }
    }, [])

    const onSubmit =() =>{
        setIsLoading(true)
        let formData = new FormData()
        if(file){
            formData.append('display_pic', file)
        }
        formData.append('name', values.name)
        formData.append('manager', values.manager)
        formData.append('contact_phone', values.contact_phone)
        formData.append('ticket_id', ticket.ticket_id)

        axios.put(baseurl + 'tickets/tickets/', formData).then((resp)=>{
            //console.log(resp)
            setIsLoading(false)
            setTicket(resp.data)
            toast.success('Option updated successfully', {
                position: 'bottom-left',
                duration: 5000
            })
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
            toast.error('An error occurred', {
                position: 'bottom-left',
                duration: 5000
            })
        })
    }

    const createOption = (e) =>{
        e.preventDefault()
        setOptionLoading(true)
        axios.post(baseurl +'tickets/ticket-options', {
            ticket: ticket.id,
            name: name,
            price: price
        }).then((resp)=>{
            //console.log(resp)
            setOptionLoading(false)
            setOptions([...options, resp.data])
            toast.success('Option created successfully', {
                position: 'bottom-left',
                duration: 5000
            })
        }).catch((err)=>{
            console.error(err)
            setOptionLoading(false)
            toast.error('An error occurred', {
                position: 'bottom-left',
                duration: 5000
            })
        })
    }

    const deleteOption = (index) =>{
        axios.delete(baseurl+'tickets/delete-option/'+options[index].id+'/').then((resp)=>{
            //console.log(resp)
            let temp_options = options
            temp_options.splice(index, 1)
            setOptions(temp_options)
        }).catch((err)=>{
            console.error(err)
        })
    }

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            name: ticket ? ticket.name: '',
            contact_phone: ticket ? ticket.contact_phone: '',
            manager: user.username,
        },
        validationSchema: CreateTicketSchema,
        onSubmit
    })

    return(
        <div id='App'>
            <Toast/>
            <Overlay/>
            <div className='main-content'>
                <br/>
                <h5 className='display-6'>Manage {ticket? ticket.name: ''}</h5>
                <div className='row mx-auto' style={{maxWidth: '100%'}}>
                    <div className='mx-auto mb-1 card content col-md-5'>
                        <form method="POST" className='forms'>
                                <fieldset>
                                    <legend className="mb-2" style={{color:'#228bdf'}}>Update</legend>                              
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputEmail4" className='form-control-lg'>Name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name='name'
                                                    value = {values.name}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    placeholder="Ticket Name"
                                                    style = {errors.name && touched.name ? {borderColor:"#fc8181"}:{}}/>
                                                    { errors.name && touched.name && <p className='error'>{errors.name}</p>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4" className='form-control-lg'>Display Pic</label>
                                                <input 
                                                    type = 'file'
                                                    required
                                                    className="form-control"
                                                    name = 'res'
                                                    
                                                    onChange={(e)=>setFile(e.target.files[0])}/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label htmlFor="inputEmail4" className='form-control-lg'>Manager</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name = 'manager'
                                                    value={values.manager}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    placeholder="Manager Username"
                                                    style = {errors.manager && touched.manager ? {borderColor:"#fc8181"}:{}}/>
                                                    { errors.manager && touched.manager && <p className='error'>{errors.manager}</p>}

                                            </div>
                                            <div className='col-md-6'>
                                                <label htmlFor="inputEmail4" className='form-control-lg'>Contact Phone</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name = 'contact_phone'
                                                    value={values.contact_phone}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    placeholder="Contact Phone"
                                                    style = {errors.contact_phone && touched.contact_phone ? {borderColor:"#fc8181"}:{}}/>
                                                    { errors.contact_phone && touched.contact_phone && <p className='error'>{errors.contact_phone}</p>}
                                            </div>
                                            
                                        </div>
                                        
                                </fieldset>
                                <div className="form-group" style={{margin: '6px'}}>
                                    <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Update"}</button>
                                </div>
                            </form>
                    </div>
                    <div className='mx-auto card content col-md-5'>
                        <form className='forms' onSubmit={createOption}>
                            <legend className='mb-2' style={{color:'#228bdf'}}>Ticket Options</legend>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Name</label>
                                    <input 
                                        type='text'
                                        className='form-control'
                                        placeholder='Name of option'
                                        name='name'
                                        required
                                        onChange={(e)=>setName(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label className='form-control-lg'>Price</label>
                                    <input 
                                        type='number'
                                        className='form-control'
                                        min='1'
                                        name='price'
                                        placeholder='Price'
                                        required
                                        onChange={(e)=>setPrice(e.target.value)}
                                    />

                                </div>
                            </div>
                            <div className='row'>
                                
                                <div className='col-md-12'>
                                    <div className="form-group" style={{margin: '6px'}}>
                                        <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}}> {optionLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Create"}</button>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    
                    <br/>
                </div>
                <div>
                        <br/>
                        <h4 className='display-6'>Ticket Options</h4>
                        <div style={{overflowX: 'auto'}}>
                            {
                                isLoaded ? 
                                    <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                    <thead style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                        <tr>
                                            <td>Name</td>
                                            <td>Price</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {
                                                    options.map((option, index)=>{
                                                        return(
                                                            <tr>
                                                                <td>{option.name}</td>
                                                                <td>{option.price}</td>
                                                                <td><button className='btn btn-danger' onClick={()=>deleteOption(index)}>Delete</button></td>
                                                                <td></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                </table>: <Loading/>
                            }
                        </div>
                        <br/>
                        <h4 className='display-6'>Attendees</h4>
                        <div style={{overflowX: 'auto'}}>
                            {
                                isLoaded ? 
                                    <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                    <thead>
                                        <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                            <td>Name</td>
                                            <td>Phone</td>
                                            <td>Email</td>
                                            <td>Ticket type</td>
                                            <td>Ticket Number</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {
                                                    attendees.map((attendee, index)=>{
                                                        return(
                                                            <tr>
                                                                <td>{attendee.first_name} {attendee.middle_name} {attendee.last_name}</td>
                                                                <td>{attendee.phone_number}</td>
                                                                <td>{attendee.email}</td>
                                                                <td>{attendee.ticket_type}</td>
                                                                <td>{attendee.ticket_number}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                </table>: <Loading/>
                            }
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ManageTicket;