import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import Toast from './Toaster';
import TextField from '@mui/material/TextField';
import ReactLoading from 'react-loading';
import toast from 'react-hot-toast';
import { GiveCodeSchema } from '../schemas';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const GiveCode = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const { state } = useLocation();

    const onSubmit = () =>{
        setIsLoading(true)
        if (state && values.code == state.code){
            setTimeout(()=>{
                setIsLoading(false)
                navigate('/reset-password', {state: {email: state.email}})
            }, 2000)
        }else{
            toast.error('Incorrect code')
            setIsLoading(false)
        }
    }
    const {values, errors,  handleBlur, touched, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: GiveCodeSchema,
        onSubmit
    })

    return(
        <>
            <div className='container'>
                <Toast/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="content-section row d-flex flex-column align-items-center justify-content-center my-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                    <div className="content-section content col-md-5 my-auto" style={{backgroundColor: "white"}}>
                
                        <form method="POST" className='forms'>
                        
                            <fieldset className='mx-auto'>
                                <legend className="border-bottom mb-4" style={{color:'#228bdf'}}>Enter Verification Code</legend>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-9'>
                                            <TextField 
                                                fullWidth required 
                                                error={errors.email && touched.email}
                                                id="standard-basic" 
                                                label="Enter Code" 
                                                variant="standard" 
                                                style={{marginLeft: '5px', borderColor: 'red'}}
                                                name = 'code'
                                                value = {values.code}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                            />
                                            { errors.code && touched.code && <p className='error'>{errors.code}</p>}
                                            
                                        </div>
                                        
                                    </div>                          
                                    
                            </fieldset>
                            <div className="form-group pt-3">
                                <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GiveCode;