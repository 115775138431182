import React from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import ReactLoading from 'react-loading'
import axios from 'axios'
import Overlay from '../overlay'
import { PurchaseLessonSchema } from '../../schemas'
import mode from '../Mode'
import Toast from '../Toaster'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'

const BuyLesson = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/': 'http://127.0.0.1:8000/';
    const token = useSelector((state)=>state.auth.token)

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }

    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    const onSubmit = () =>{
        //sending the stk push request
        setIsLoading(true)
        var phoneNu = values.phone
        var updated_num = "+254" + phoneNu[1]+ phoneNu[2]+ phoneNu[3]+ phoneNu[4]+ phoneNu[5]+ phoneNu[6]+ phoneNu[7]+ phoneNu[8]+ phoneNu[9]
        axios.post(baseurl+'payments/checkout/', {
            phone_number: updated_num,
            amount: Number(values.type),
            reference: "lesson",
            description: "lesson",
            billref: 'lesson'
        }).then((response)=>{
            //console.log(response)
            toast.success("An Mpesa prompt has been sent to your phone. Kindly don't exit this page until you receive a feedback message", {
                position: 'top-right',
                duration: 20000,
            })
            //checking the status of the payment
            delay(response)
        }).catch((err)=>{ // to be changed.
            console.error(err)
            setIsLoading(false)
            toast.error('An error occurred')
        })
    }
    
    const delay = (r) => {
        setTimeout(()=>{
            checkPaymentStatus(r.data.billref)
        }, 32000)
    }

    const checkPaymentStatus = (id) => {
        axios.get( baseurl + 'payments/check-transaction/'+id).then((response) => {
            if(!response.data.status){                
                axios.post(baseurl + 'ss/lessonSubscriber/',{
                    phone: values.phone,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    lesson_type: values.type
                }, config).then((resp)=>{
                    //console.log(resp)
                    toast.success("Feedback: Lesson bought successfully. ", {
                        position: 'top-center',
                        duration: 5000,
                        style: {
                            background: 'greenYellow',
                            color: '#fff',
                            fontSize: '1.1em'
                        },
                    })
                    setIsLoading(false)
                }).catch((err)=>{
                    console.error(err)
                    setIsLoading(false)
                    toast.error('Payment made but an error occurred when saving record. Contact JKUSDA Sabbath School.')
                })
            }else{
                setIsLoading(false)
                console.log(response)
                toast.error('Transaction incomplete')
            }
        }).catch((error)=>{
            console.error(error)
            toast.error('This feature will be available from tomorrow')
        })
    }
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone: '',
            type: ''
        },
        validationSchema: PurchaseLessonSchema,
        onSubmit
    })
    return (
        <div className="App">
            <Overlay/>
            <Toast/>
            <div className="main-content">
            <br/>
            <br/>
            <br/>
            <div className='content-section'>
            <div className="row d-flex flex-column align-items-center justify-content-center m-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                <div className="content col-md-7 m-auto" style={{backgroundColor: "white"}}>
                    <form method="POST" className='forms'>
                        <fieldset>
                            <legend className="mb-4" style={{color:'#228bdf'}}>Buy Lesson</legend>                             
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4" className='form-control-lg'>First Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='first_name'
                                            value = {values.first_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="First Name"
                                            style = {errors.first_name && touched.first_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.first_name && touched.first_name && <p className='error'>{errors.first_name}</p>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Last Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='last_name'
                                            value = {values.last_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Last Name"
                                            style = {errors.last_name && touched.last_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.last_name && touched.last_name && <p className='error'>{errors.last_name}</p>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-md-6'>
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Mpesa Number</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='phone'
                                            value = {values.phone}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Mpesa Phone Number"
                                            style = {errors.phone && touched.phone ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.phone && touched.phone && <p className='error'>{errors.phone}</p>}
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Lesson Type</label>
                                        <select 
                                            type="text" 
                                            className="form-control" 
                                            name='type'
                                            value = {values.type}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Select"
                                            style = {errors.type && touched.type ? {borderColor:"#fc8181"}:{}}>
                                            <option value=''></option>
                                            <option value='1'>Adult Lesson - Ksh 120</option>
                                            <option value='2'>Teacher's Lesson - Ksh 240</option>
                                        </select>
                                            { errors.type && touched.type && <p className='error'>{errors.type}</p>}
                                    </div>
                                </div>                                
                        </fieldset>
                        <div className="form-group" style={{margin: '6px'}}>
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Buy"}</button>
                        </div>
                    </form>
                </div>
            </div>
            <br/>
        </div>  
                
            </div>
        </div>
    )
}

export default BuyLesson