import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Overlay from '../overlay';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import Toast from '../Toaster';
import { toast } from 'react-hot-toast';
import 'react-datepicker/dist/react-datepicker.css';
import { setJkToken } from '../../Slices/auth';
import { useTokenManager } from '../TokenManager';
import mode from '../Mode';

const MemberContribution = () =>{
    const [isLoading, setIsLoading] = useState(true)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [contributions, setContributions] = useState([])
    const [payments, setPayments] = useState([])
    const [total, setTotal] = useState(0)
    const member = useSelector((state)=>state.auth.member)

    const jkToken = useTokenManager('dummy')

    const cont_incl = [1, 64, 15, 26, 14, 79, 50, 103, 111]
    
    const baseurl = mode ? 'https://api.jkusdachurch.org/' : 'https://sandbox.jkusdachurch.org/';
    const navigate = useNavigate()

    const config = {
        headers: {
            'Authorization': 'Bearer ' + jkToken
        }
    }

    useEffect(()=>{
        //get conntibutions
        if (member == null){
            navigate('/login', {state: {reason: 'Unauthorized'}})
        }
        
        axios.get(baseurl + 'treasury/contribution/', config)
            .then(function (response) {
                setContributions(response.data.data);
                console.log(response.data.data);
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [jkToken])

    const {values, error, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            contribution: '',
            
        }
    })

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const queryData = ()=>{
        setIsLoading(true)
        var url = baseurl + 'treasury/payment/';

        var params = {
        }
        if (values.contribution){
            params['contribution_id'] = values.contribution
        }
        if (toDate){
            params['to'] = (formatDate(toDate))
        }
        params['member_id'] = member.member.id
        if (fromDate) {
            params['from'] = (formatDate(fromDate))
        }

        var paymentConfig = {
            headers: {
                'Authorization': 'Bearer ' + jkToken
            },
            params: params
        }

        axios.get(url, paymentConfig).then((resp)=>{
            var payment_data = resp.data.data
            payment_data.reverse() //order from latest to oldest in terms of date
            setPayments(payment_data)

            //get total
            var sum = 0;
            (resp.data.data).forEach((p)=>{
                sum += p.amount;
            })
            
            setTotal(sum)
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
            toast.error('An error occured. Kindly report to treasury.', {
                duration: 5000,
                position: 'top-center'
            })
        })
    }

    return(
        <div className='App'>
            <Overlay/>
            <Toast/>
            <br/>
            <h5 className='display-5'>My Contributions</h5>
            <div className='main-content'>
                <div className='row content-section content'>
                    <form className='col-md-10 forms' style={{marginBottom: '20px'}}>
                        <div className='row justify-content-around'>
                            <div className='col-md-4'>
                                <label className='form-control-lg'>Contribution</label>
                                <select
                                    className='form-control'
                                    name='contribution'
                                    value = {values.contribution}
                                    onChange={handleChange}
                                >   
                                <option value=''></option>  
                                {
                                    contributions.map((contribution, key)=>{
                                        if (cont_incl.includes(contribution.id)){
                                            return(
                                                <option value={contribution.id} key={key}>{contribution.name}</option>
                                            )
                                        }
                                        
                                    })
                                }                              
                                </select>
                            </div>
                            <div className='col-md-3'>
                                <label className='form-control-lg'>From</label><br/>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className='form-control'
                                />
                            </div>
                            <div className='col-md-3'>
                                <label className='form-control-lg'>To</label><br/>
                                <DatePicker
                                    selected={toDate}
                                    onChange={(date) => setToDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className='form-control'
                                />
                            </div>
                        </div>
                    </form>
                    <div className='col-md-2'>
                        <button className="btn btn-primary query-btn" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={queryData}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Query"}</button>
                    </div>
                </div>
                <br/>
                <div><h5>Total: {total}</h5></div>
                <div style={{overflowX: 'auto'}}>
                    <table className="table table-secondary table-bordered table-striped table-hover" style={{color: 'grey'}}>
                        <thead>
                            <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                <td style={{minWidth: '130px', width: '10%'}}>Contribution</td>
                                <td style={{minWidth: '120px', width: '5%'}}>Amount</td>
                                <td style={{minWidth: '130px', width: '10%'}}>Date</td> 
                                <td style={{minWidth: '130px', width: '10%'}}>Via</td>
                                                               
                            </tr>
                        </thead>
                        <tbody>
                            {
                                payments.map((payment, key)=>{
                                    return(
                                        <tr key={key}>
                                            <td>{payment.contribution}</td>
                                            <td>{payment.amount}</td>
                                            <td>{payment.date_created}</td>
                                            <td>{payment.description}</td>
                                                                                        
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MemberContribution;