import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../Slices/auth';
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from '@mui/icons-material/Close';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import WorkIcon from '@mui/icons-material/Work';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import BookIcon from '@mui/icons-material/Book';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import AddBoxIcon from '@mui/icons-material/AddBox';
import SavingsIcon from '@mui/icons-material/Savings';
import PaymentsIcon from '@mui/icons-material/Payments';
import ChurchIcon from '@mui/icons-material/Church';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';

const Overlay = () =>{
    const userType =useSelector((state)=>state.auth.userType)
    const user = useSelector((state)=>state.auth.user)
    const member = useSelector((state)=>state.auth.member)
    const [opened, setOpened] = useState(window.innerWidth < 700)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { collapseSidebar } = useProSidebar()

    const logout = () => {
        dispatch(logoutUser())
        navigate('/')
    }
    
    return (
            <>
                    <Sidebar defaultCollapsed={window.innerWidth > 1000} style={{position: 'fixed', textAlign: 'left'}} className="sideBarContainer">
                        <Menu>
                            <MenuItem
                                icon={opened? <CloseIcon sx={{fontSize: 28}}/>:<MenuOutlinedIcon />}
                                onClick={() => {
                                    collapseSidebar();
                                    setOpened(!opened);
                                }}
                                style={{ textAlign: "center" }}
                            >
                                {" "}
                                <h3>{user ? 'JKUSDA' : logout()}</h3>
                            </MenuItem>
                            <MenuItem icon={<DashboardIcon />} component={<Link to='/dashboard'/>}>Dashboard</MenuItem>
                            <SubMenu label='Treasury' icon={<SavingsIcon/>}>
                                <MenuItem icon={<PaymentsIcon/>} component={<Link to='/my-contributions'/>}>My Contributions</MenuItem>
                                <MenuItem icon={<ChurchIcon/>} component={<Link to='/make-payments'/>}>Make Payments</MenuItem>

                            </SubMenu>
                            <SubMenu label='Nurturing' icon={<FamilyRestroomIcon/>} defaultCollapsed={true}>
                                <MenuItem icon={<Diversity1Icon />} component={<Link to='/my-family'/>}>My Family</MenuItem>
                                <MenuItem icon={<Diversity3Icon />} component={<Link to='/all-families'/>}>All Families</MenuItem>
                                <MenuItem icon={<ConnectWithoutContactIcon />} component={<Link to='/contact-us'/>}>Contact Nurturing</MenuItem>
                                {
                                    (userType.includes("Nurturing Admin") || userType.includes("Super Admin"))  ? <>
                                        <MenuItem icon={<Diversity2Icon />} component={<Link to='/admin-generate-families'/>}>Generate Families</MenuItem>
                                        <MenuItem icon={<FilterAltIcon />} component={<Link to='/admin-filter-parents'/>}>Filter Parents</MenuItem>
                                        <MenuItem icon={<UploadFileIcon />} component={<Link to='/admin-upload-resources'/>}>Upload Resources</MenuItem>
                                    </>:null
                                }
                            </SubMenu>
                            <SubMenu label='Publishing' icon={<LocalLibraryIcon/>}>
                                    <MenuItem icon={<BookIcon />} component={<Link to='/publishing'/>}>View Books</MenuItem>
                                    
                                    {
                                        (userType.includes("Publishing Admin") || userType.includes("Super Admin")) ?<>
                                            <MenuItem icon={<PeopleIcon />} component={<Link to='/books-manager'/>}>Books Manager</MenuItem>
                                            
                                        </>: null
                                    }
                            </SubMenu>
                            <SubMenu label='Sabbath School' icon={<LocalLibraryIcon/>}>
                                <MenuItem icon={<BookIcon />} component={<Link to='/buy-lesson'/>}>Buy Lesson</MenuItem>
                                
                                {
                                    (userType.includes("Sabbath School Admin") || userType.includes("Super Admin")) ?<>
                                        <MenuItem icon={<PeopleIcon />} component={<Link to='/lesson-subscribers'/>}>Lesson Subscribers</MenuItem>
                                        
                                    </>: null
                                }
                            </SubMenu>

                            {
                                userType.includes("Super Admin") ?<>
                                    <MenuItem icon={<WorkIcon />} component={<Link to='/admin-general-roless'/>}>Roles</MenuItem>
                                    <MenuItem icon={<AddBoxIcon />} component={<Link to='/create-ticket'/>}>Create Ticket</MenuItem>
                                </>:null
                            }
                            <MenuItem icon={<ConfirmationNumberIcon />} component={<Link to='/tickets'/>}>Tickets</MenuItem>
                            <MenuItem icon={<AssignmentReturnedIcon />} component={<Link to='/report-session'/>}>Report Session</MenuItem>
                            <SubMenu label='My Account' icon={<LocalLibraryIcon/>}>
                                <MenuItem icon={<AssignmentReturnedIcon />} component={<Link to='/my-account'/>}>Update Details</MenuItem>
                                <MenuItem icon={<AddModeratorIcon />} component={<Link to='/change-password'/>}>Change Password</MenuItem>
                            </SubMenu>
                            <MenuItem icon={<LogoutIcon />} onClick={()=> logout()}>LogOut</MenuItem>
                            <br/>
                            <br/>
                        </Menu>
                    </Sidebar>
            </>
        )
    }

export default Overlay;