import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import axios from 'axios'
import Toast from './Toaster'
import { toast } from 'react-hot-toast'
import ReactLoading from 'react-loading'
import { logoutUser, setMember, setUser } from '../Slices/auth'
import mode from './Mode'
import { useNavigate } from 'react-router-dom'
import { memberRegisterSchema } from '../schemas'
import Overlay from './overlay'

const MyAccount = () =>{

    //authorization
    const user = useSelector((state)=>state.auth.user);
    const member = useSelector((state)=>state.auth.member);
    const names = (member.member.name).split(" ")
    const token = useSelector((state)=>state.auth.token);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/': 'http://127.0.0.1:8000/general/';


    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }
    const dispatch = useDispatch()

    useEffect(()=>{
        if (user == null){
            toast.error('Unauthorized, login', {
                duration: 5000,
                position: 'bottom-left'
            })
            navigate('/login', {state: {reason: 'Unauthorized'}})
        }
    }, [])

    const onSubmit = ()=>{
        setIsLoading(true)
        axios.put(baseurl + 'update-member/'+ user.username +"/", {
            user:{
                username: values.email,
                email: values.email
            },
            member:{
                name: values.first_name + " " + values.last_name,
                course: values.course,
                baptismal_status: Number(values.baptised),
                yos: Number(values.intake),
                gender: values.gender,
                regno: values.reg_no,
            },
            phone:{
                member_phone: values.phone_number,
            },
            authMember: {
                next_of_kin: values.next_of_kin,
                home_church: values.home_church,
            }
        }, config).then((response)=>{
            //console.log(response.data)
            dispatch(setMember(response.data))  
            dispatch(setUser(response.data.user))    
            setIsLoading(false);
            toast.success('Successful updated your profile', {
                duration: 5000,
                position: 'bottom-right'
            })      
        }).catch((err)=>{
            console.error(err);
            setIsLoading(false)
            if (err.response && err.response.data.member_phone){
                toast.error(err.response.data.member_phone[0])
            }else if(err.response && err.response.data.username){
                toast.error(err.response.data.username[0])
            }else if(err.response && err.response.data.email){
                toast.error(err.response.data.email[0])
            }
            else{
                toast.error('An Error occurred')
            }
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
        })
    }

    const {values, errors,  handleBlur, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            first_name: names[0],
            last_name: names.length > 0 ? names[1] : '',
            course: member.member.course,
            next_of_kin: member.next_of_kin,
            home_church: member.home_church,
            baptised: member.member.baptismal_status,
            phone_number: member.phone.member_phone,
            intake: member.member.yos,
            gender: member.member.gender,
            reg_no: member.member.regno,
            username: user ? user.username: '',
            email:user ? user.email: ''
        },
        validationSchema: memberRegisterSchema,
        onSubmit
    })
    return(
        <>
            <Toast/>
            <Overlay/>
            <br/>
            <br/>
            <div className='container content-section'>
            <div className="row d-flex flex-column align-items-center justify-content-center m-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                <div className="content-section content col-md-10 m-auto" style={{backgroundColor: "white"}}>
                    <form method="POST" className="forms">
                        <fieldset>
                            <legend className="mb-4" style={{color:'#228bdf'}}>Update Details</legend>
                            <div className="row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Email</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            name='email'
                                            value = {values.email}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Email"
                                            style = {errors.email && touched.email ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.email && touched.email && <p className='error'>{errors.email}</p>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4" className='form-control-lg'>First Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='first_name'
                                            value = {values.first_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="First Name"
                                            style = {errors.first_name && touched.first_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.first_name && touched.first_name && <p className='error'>{errors.first_name}</p>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Last Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='last_name'
                                            value = {values.last_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Last Name"
                                            style = {errors.last_name && touched.last_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.last_name && touched.last_name && <p className='error'>{errors.last_name}</p>}
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="form-group col-md-3">
                                            <label htmlFor="inputPassword4" className='form-control-lg'>Phone Number</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name='phone_number'
                                                value = {values.phone_number}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                placeholder="07...or 01..."
                                                style = {errors.phone_number && touched.phone_number ? {borderColor:"#fc8181"}:{}}/>
                                                { errors.phone_number && touched.phone_number && <p className='error'>{errors.phone_number}</p>}
                                    </div>
                                    <div className="form-group col-md-3">
                                            <label htmlFor="inputPassword4" className='form-control-lg'>Gender</label>
                                            <select
                                                className="form-select" 
                                                name='gender'
                                                value = {values.gender}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                                placeholder="Gender"
                                                style = {errors.phone_number && touched.phone_number ? {borderColor:"#fc8181"}:{}}>
                                                <option value=""></option>
                                                <option value="M">MALE</option>
                                                <option value="F">FEMALE</option>
                                            </select>
                                                { errors.phone_number && touched.phone_number && <p className='error'>{errors.phone_number}</p>}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4" className='form-control-lg'>Intake</label>
                                        <select 
                                            className="form-select" 
                                            name='intake'
                                            value = {values.intake}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Year of Intake"
                                            style = {errors.intake && touched.intake ? {borderColor:"#fc8181"}:{}}>
                                            <option value={values.intake} selected>{values.intake}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                        </select>
                                            { errors.intake && touched.intake && <p className='error'>{errors.intake}</p>}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className='form-control-lg'>Baptised</label>
                                        <select
                                            className='form-select'
                                            name = 'baptised'
                                            value = {values.baptised}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Baptised or not"
                                            style = {errors.baptised && touched.baptised ? {borderColor:"#fc8181"}:{}}>
                                            <option value={values.baptised} selected>{values.baptised ? 'YES': 'NO'}</option>
                                            <option value="1" selected>YES</option>
                                            <option value="0">NO</option>
                                        </select>
                                            { errors.baptised && touched.baptised && <p className='error'>{errors.baptised}</p>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className='form-control-lg'>Course</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name = 'course'
                                            value = {values.course}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Course"
                                            style = {errors.course && touched.course ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.course && touched.course && <p className='error'>{errors.course}</p>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className='form-control-lg'>Registration Number</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name = 'reg_no'
                                            value = {values.reg_no}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="School Reg Number"
                                            style = {errors.course && touched.course ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.reg_no && touched.reg_no && <p className='error'>{errors.reg_no}</p>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className='form-control-lg'>Next of Kin</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name = 'next_of_kin'
                                            value = {values.next_of_kin}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Phone No: 07...or 01..."
                                            style = {errors.next_of_kin && touched.next_of_kin ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.next_of_kin && touched.next_of_kin && <p className='error'>{errors.next_of_kin}</p>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className='form-control-lg'>Home Church</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name = 'home_church'
                                            value = {values.home_church}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Home Church"
                                            style = {errors.home_church && touched.home_church ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.home_church && touched.home_church && <p className='error'>{errors.home_church}</p>}
                                    </div>
                                </div>                                
                                
                        </fieldset>
                        <div className="form-group pt-3">
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
            <br/>
        </div>
        </>
    )
}

export default MyAccount;