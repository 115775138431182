import React from 'react'
import ReactLoading from 'react-loading'

const Loading = () =>{
    return(
        <div>
            <br/>
            <br/>
            <button className='loadingButton'><ReactLoading type="bubbles" color="grey" height={150} width={150} /></button>
        </div>
    )
}

export default Loading