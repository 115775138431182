import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import Overlay from '../../overlay'
import { GenerateFamiliesSchema } from '../../../schemas'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../Slices/auth'
import mode from '../../Mode'

const GenerateFamilies = () =>{
    const token = useSelector((state)=>state.auth.token)
    const userType = useSelector((state)=>state.auth.userType)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/nurturing/': 'http://127.0.0.1:8000/nurturing/';
    const [data, setData] = useState({})
    const [familiesList, setFamiliesList] = useState([])
    const [indexList, setIndexList] = useState([])
    const [parentsList, setParentsList] = useState([])
    const [femReported, setFemReported] = useState(' ')
    const [maleReported, setMaleReported] = useState(' ')

    const dispatch = useDispatch()

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        if (!(userType.includes('Nurturing Admin') || userType.includes('Super Admin'))){
            navigate('/dashboard')
        }
        axios.get(`${baseurl}all-families/`, config).then((resp)=>{
            setData(resp.data)
            setFamiliesList(resp.data.families_list)
            setIndexList(resp.data.indexList)
            setParentsList(resp.data.parents)
            setFemReported(resp.data.femReported)
            setMaleReported(resp.data.maleReported)
        }).catch((err)=>{
            console.error(err)
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
        })
    }, [])

    const onSubmit = () =>{
        setIsLoading(true)
        axios.post(`${baseurl}randomize/`, {
            number_of_families: values.numberOfFamilies
        }, config).then((resp)=>{
            setData(resp.data)
            setFamiliesList(resp.data.families_list)
            setIndexList(resp.data.indexList)
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
        })
    }


    const { values, errors,  handleBlur, touched, isSubmitting, handleChange, handleSubmit  } = useFormik({
        initialValues: {
            numberOfFamilies: ''
        },
        validationSchema: GenerateFamiliesSchema,
        onSubmit
    })

    return(
        <>
            <div id="App">
                <Overlay/>  
                <div className="main-content">
                    <br/>        
                    <div className="generateBody">
                        <div className='card mb-4 content generateItem'>
                            <h5 style={{color:'#000'}}>Total Reported:</h5><h4>{femReported + maleReported}</h4>
                            <h5 style={{color:'black'}}>Male:</h5><h4>{femReported}</h4>
                            <h5 style={{color:'black'}}>FeMale:</h5><h4>{maleReported}</h4>
                        </div>
                        <div className='card mb-4 content generateItem'>
                            <form method="POST">
                                
                                <fieldset>
                                    <legend className="border-bottom mb-4" style={{color:'black'}}>Generate Families</legend>
                                        <label className='form-control-lg'>Number Of Families</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            name = 'numberOfFamilies'
                                            value = {values.numberOfFamilies}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            style = {errors.numberOfFamilies && touched.numberOfFamilies ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.numberOfFamilies && touched.numberOfFamilies && <p className='error'>{errors.numberOfFamilies}</p>}
                                </fieldset>
                                <br/>
                                <div className="form-group">
                                    <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Generate"}</button>
                                </div>
                            </form>

                        </div>
                    </div>
                    <br/>

                {
                    familiesList.map((fm, index)=>{
                        return(
                            <>
                                <h4 className='display-6'>Family {fm}</h4>
                                <table className="table table-active table-striped table-hover" style={{color: 'grey'}}>
                                    <thead>
                                        <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                            <td>Name</td>
                                            <td>Year Group</td>
                                            <td>Phone Number</td>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            parentsList.map((parent, index)=>{
                                                if(parent.number == fm){
                                                    return(
                                                        <>
                                                            {
                                                                parent.dad?
                                                                    <tr>
                                                                        <td><b>{`${parent.dad.member.name} - Dad`}</b></td>
                                                                        <td><b>{parent.dad.member.yos}</b></td>
                                                                        <td><b>{parent.dad.phone.member_phone}</b></td>
                                                                    </tr>
                                                                :null
                                                            }
                                                            {
                                                                parent.mom?
                                                                <tr>
                                                                    <td><b>{`${parent.mom.member.name} - Mom`}</b></td>
                                                                    <td><b>{parent.mom.member.yos}</b></td>
                                                                    <td><b>{parent.mom.phone.member_phone}</b></td>
                                                                </tr>
                                                                :null
                                                            }
                                                        
                                                        </>
                                                        
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            indexList.map((i, index)=>{
                                                if(data.family[i] == fm){
                                                    return(
                                                        <tr>
                                                            <td>{`${data.name[i]}`}</td>
                                                            <td>{data.intake[i]}</td>
                                                            <td>{data.phone_number[i]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    
                                    </tbody>
                                </table>
                            </>
                        )
                    })
                }
                </div>
            </div>
           
        </>
    )
}

export default GenerateFamilies