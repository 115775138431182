import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Overlay from './overlay';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Skeleton from '@mui/material/Skeleton';
import { LazyLoadImage } from "react-lazy-load-image-component";
import mode from "./Mode";

const ImagePLaceHolder = () =>{
  return(
    <div className="col-lg-4 col-md-12">
      <Skeleton variant="rectangular" width={320} height={320} />             
    </div>
  )
}

const Gallery = () =>{
    const [images, setImages] = useState([])

    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/': 'http://127.0.0.1:8000/general/';

    useEffect(()=>{
      axios.get(baseurl+'get-images/').then((resp)=>{
        console.log(resp)
        setImages(resp.data.resources)
      })
    }, [])

    const itemData = [
        {
          img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
          title: 'Breakfast',
        },
        {
          img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
          title: 'Burger',
        },
        {
          img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
          title: 'Camera',
        },
        {
          img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
          title: 'Coffee',
        },
        {
          img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
          title: 'Hats',
        },
        {
          img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
          title: 'Honey',
        },
        {
          img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
          title: 'Basketball',
        },
        {
          img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
          title: 'Fern',
        },
        {
          img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
          title: 'Mushrooms',
        },
        {
          img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
          title: 'Tomato basil',
        },
        {
          img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
          title: 'Sea star',
        },
        {
          img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
          title: 'Bike',
        },
        {
          img: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
          title: 'Bike',
        },
        {
          img: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp',
          title: 'Bike',
        },
        {
          img: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain2.webp',
          title: 'Bike',
        },
        {
          img: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
          title: 'Bike',
        },
        {
          img: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(18).webp',
          title: 'Bike',
        },
        {
          img: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain3.webp',
          title: 'Bike',
        },
        
      ];
    return(
        <div className='App'>
            <Overlay/>
            <br/>
            <div className='main-content'>
                <div className="row">
                    {
                        itemData.map((image)=>{
                            return(
                                <div className="col-lg-4 col-md-12 mb-1 mb-lg-0">
                                    <LazyLoadImage
                                      src={image.img}
                                      placeholder={<ImagePLaceHolder/>}
                                      effect="blur"
                                      className="w-100 shadow-1-strong rounded mb-1"
                                      alt="Boat on Calm Water"
                                    />
                                    
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default Gallery;