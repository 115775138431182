import {React, useState, useEffect} from 'react'
import Overlay from './overlay';
import ReactLoading from 'react-loading';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, setMember } from '../Slices/auth';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Toast from './Toaster';
import { useNavigate } from 'react-router-dom';
import mode from './Mode';

const ReportSession = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingRevoke, setIsLoadingRevoke] = useState(false)
    const [sessions, setSessions] = useState([])
    const dispatch = useDispatch()
    const user = useSelector((state)=>state.auth.user)
    const navigate = useNavigate()
    const token = useSelector((state)=>state.auth.token)
    const member = useSelector((state)=>state.auth.member)
    const [currentReportStatus, setCurrentReportStatus] = useState(1)
    const userType =useSelector((state)=>state.auth.userType)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/': 'http://127.0.0.1:8000/general/';

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        //if user is null, redirect to login page
        if (user==null){
            dispatch(logoutUser())
            toast.error('Unauthorized Access', {
                duration: 5000,
                position: 'bottom-left'
            })
            navigate('/login')
        }
        axios.get(`${baseurl}report-session/${user.id}/`, config).then((resp)=>{
            console.log(resp.data)
            setSessions(resp.data.sessions)
            setCurrentReportStatus(resp.data.current_report_status)
        }).catch((err)=>{
            console.error(err)
            if(err.response.status == 401){
                toast.error('Unauthorized, kindly login', {
                    position: 'bottom-left',
                    duration: 7000
                })
                dispatch(logoutUser())
                navigate('/login')
            }
        })
    }, [])

    const handleSubmit = () =>{
        setIsLoading(true)
        axios.post(`${baseurl}report-session/${user.id}/`,{}, config).then((resp)=>{
            //console.log(resp);
            setSessions(resp.data)
            setCurrentReportStatus(1)
            dispatch(setMember({...member, report_status: 1}))
            setIsLoading(false)
            toast.success('Reporting Successful', {
                position: 'bottom-left',
                duration: 3000
            })

        }).catch((err)=>{
            console.error(err);
            setIsLoading(false)
        })
    }

    const revokeSessions = () =>{
        setIsLoadingRevoke(true)
        axios.get(baseurl + 'revoke-session-reported/', config).then((resp)=>{
            //console.log(resp)
            toast.success("Done!", {
                position: 'bottom-left',
                duration: 2000
            })
            setCurrentReportStatus(0)
            setIsLoadingRevoke(false)
        }).catch((err)=>{
            toast.error("An error occurred", {
                position: 'bottom-left',
                duration: 2000
            })
            setIsLoadingRevoke(false)
        })
    }
    return(
        <div className="App">
            <Toast/>
            <Overlay/>
            <div className="main-content">
                <br/>
                <h5 style={{color: '#228bdf'}} className='display-5'>Session Reporting</h5>
                <h3 style={{color: '#565656f4'}}>History</h3>
                <table className="table table-secondary table-striped table-hover table-bordered" style={{color: 'white'}}>
                        <thead>
                            <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                <td></td>
                                <td>Date</td>
                               
                            </tr>
                        </thead>
                        <tbody >
                            {sessions.map((session, index)=>(
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{session.date}</td>
                                </tr>
                            ))}
                           
                        </tbody>
                </table>
                <h5 style={{textAlign: 'left'}}>Report Status:</h5>
                {
                    currentReportStatus?
                        <>
                            <h6 style={{color: '#29b8b0', textAlign: 'left'}}>Reported</h6>
                            <div className="form-group" >
                                <button className="btn btn-outline-info" type="submit" disabled={true} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Report"}</button>
                            </div>
                            
                        </>
                    :
                    <>
                        <div className="form-group" >
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Report"}</button>
                        </div>
                        <h6 style={{color: '#29b8b0', textAlign: 'left'}}>Not Reported</h6>
                    </>
                }
                <br/>
                {
                    userType.includes("Super Admin")?
                        <button type="button" className="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Revoke all
                        </button>:null

                }
                
                
            </div>

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <b>Are you sure about this action? This will set all sessions to <b style={{color:'red'}}>unreported status</b>.</b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"  disabled={isLoadingRevoke} data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" disabled={isLoadingRevoke} onClick={revokeSessions}>Revoke</button>
                        </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default ReportSession;