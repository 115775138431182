import React from "react";
import Overlay from "./overlay";
import ErrorIcon from '@mui/icons-material/Error';

const Error = () =>{
    return(
        <div id="App">
            <Overlay/>
            <div className="main-content">
                <br/>
                <br/>
                <h1 className="display-1"><b>404 Not Found</b></h1>
                <ErrorIcon sx={{ fontSize: 100 }} />
                
            </div>
        </div>
    )
}

export default Error