import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Overlay from '../../overlay'
import axios from 'axios'
import ReactLoading from 'react-loading'
import { toast } from 'react-hot-toast'
import Toast from '../../Toaster'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../../Slices/auth';
import mode from '../../Mode'

const Parents = () =>{
    const navigate = useNavigate()
    const userType = useSelector((state)=>state.auth.userType)
    
    const [members, setMembers] = useState([])
    const [parents, setParents] = useState([])
    const [addIsLoading, setAddIsLoading] = useState([])
    const [selectMom, setSelectMom] = useState("")
    const [selectDad, setSelectDad] = useState("")
    const [familyNo, setFamilyNo] = useState("")
    const [parentPairIsLoading, setParentPairIsLoading] = useState(false)
    const [parentPairs, setParentPairs] = useState([])
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/nurturing/': 'http://127.0.0.1:8000/nurturing/';

    const dispatch = useDispatch()

    const token = useSelector((state)=> state.auth.token)

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        if (!(userType.includes('Nurturing Admin') || userType.includes('Super Admin'))){
            navigate('/dashboard')
        }
        axios.get(`${baseurl}get-reported-members`, config).then((resp)=>{
            setMembers(resp.data)
            const loadingArr = new Array(resp.data.length).fill(false)
            setAddIsLoading(loadingArr)
            axios.get(`${baseurl}get-parents`, config).then((response)=>{
                setParents(response.data)
                //console.log(response.data)

                axios.get(`${baseurl}parent-pair/`, config).then((r)=>{
                    //console.log(r.data)
                    setParentPairs(r.data)
                })
            })
        }).catch((err)=>{
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
        })
    }, [])

    const addParent = (authMember, index) =>{
        const {id, member, phone} = authMember
        axios.post(`${baseurl}parents/add`, {
            id: id,
            name: member.name,
            intake: member.yos,
            phone_number: phone.member_phone,
            gender: member.gender
        }, config).then((resp)=>{
            const tempMembers = [...members]
            //remove the member from the members array
            const newMembers = tempMembers.splice(index, 1)
            setMembers(tempMembers)
            //add the member to to the parents array
            setParents([...parents, authMember])
            //setAddIsLoading(false)
        }).catch((err)=>{
            console.error(err);
            //setAddIsLoading(false)
        })
    }

    const removeParent = (authMember, index) =>{
        const {id, member, phone} = authMember
        axios.post(`${baseurl}parents/remove`, {
            id: id,
            name: member.name,
            intake: member.yos,
            phone_number: phone.member_phone,
            gender: member.gender
        }, config).then((resp)=>{
            const tempParents = [...parents]
            const newParents = tempParents.splice(index, 1)
            setParents(tempParents)
            setMembers([...members, authMember])
            //console.log(resp)
        }).catch((err)=>{
            console.error(err)
        })
    }

    const FamilyNoExists = (fNo) => {
        var exists = false
        //console.log(fNo)
        //console.log(parentPairs)
        parentPairs.map((pair, index) =>{
            console.log(pair.number)
            if(fNo == pair.number){
                console.log("There")
                exists = true
            }
        })
        console.log("Not there")
        return exists
    }

    const saveParentPair = (index) =>{
        setParentPairIsLoading(true)
        if(isNaN(selectDad) || selectDad >= parents.length || selectDad < 0 || selectDad == ""){
            toast.error('Invalid Dad Option',{
                duration: 3000,
                position: 'top-center'
            })
            setParentPairIsLoading(false)
        }
        else if (isNaN(selectMom) || selectMom >= parents.length || selectMom < 0 || selectMom == ""){
            toast.error('Invalid Mum Option',{
                duration: 3000,
                position: 'top-center'
            })
            setParentPairIsLoading(false)
        }
        else if(familyNo == 0){
            toast.error('Choose a valid Family No',{
                duration: 3000,
                position: 'top-center'
            })
            setParentPairIsLoading(false)
        }else if(FamilyNoExists(familyNo)){
            toast.error('Family Number Exists',{
                duration: 3000,
                position: 'top-center'
            })
            setParentPairIsLoading(false)
        }else{
            axios.post(`${baseurl}parent-pair/`, {
                dad: parents[selectDad].id,
                mom: parents[selectMom].id,
                number: Number(familyNo)
            }, config).then((resp)=>{
                //console.log(resp)
                setParentPairs(resp.data)
                setParentPairIsLoading(false)
    
                axios.get(`${baseurl}get-parents`, config).then((response)=>{
                    setParents(response.data)
                    console.log(response.data)
                    setSelectDad("")
                    setSelectMom("")
                })
            }).catch((err)=>{
                console.error(err)
                setParentPairIsLoading(false)
            })
        }
    }

    const deleteParentPair = (pair, index) =>{
        axios.post(`${baseurl}delete-parent-pair/`, {
            id: pair.id
        }, config).then((resp)=>{
            const tempPair = [...parentPairs]
            const removedPair = tempPair.splice(index, 1)
            setParents([...parents, pair.dad, pair.mom])
            //console.log(resp.data)
            setParentPairs(resp.data)
        }).catch((err)=>{
            console.error(err)
        })
    }

    return(
        <>
            <div id='App'>
                <Toast/>
                <Overlay/>             
                <div className='main-content' style={{ textAlign: 'center'}}>
                <br/>
                    <h1 style={{color: '#228bdf'}}>Filter Dads and Moms</h1>
                    <br/>
                    <h3>Dads</h3>
                    <table className="table table-active table-striped table-hover" style={{color: 'grey'}}>
                        <thead>
                            <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                <td>Name</td>
                                <td>Group</td>
                                <td>Number</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody >
                        {
                                parents.map((member, index)=>{
                                    
                                    if(member.member.gender=='M'){
                                       
                                        return(
                                            <tr>
                                                <td>{member.member.name}</td>
                                                <td>{member.member.yos}</td>
                                                <td>{member.phone.member_phone}</td>
                                                <td><button className='btn btn-sm btn-danger' onClick={()=>removeParent(member, index)} >Remove</button></td>
                                            </tr>
                                        )
                                    }
                                })
                            }                                    
                        </tbody>
                    </table>
                    

                    <br/>
                    <br/>
                    <h3>Moms</h3>
                    <table className="table table-active table-striped table-hover" style={{color: 'grey'}}>
                        <thead>
                            <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                <td>Name</td>
                                <td>Group</td>
                                <td>Number</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody >
                        {
                                parents.map((member, index)=>{
                                    if(member.member.gender=='F'){
                                        return(
                                            <tr>
                                                <td>{member.member.name}</td>
                                                <td>{member.member.yos}</td>
                                                <td>{member.phone.member_phone}</td>
                                                <td><button className='btn btn-sm btn-danger' onClick={()=>removeParent(member, index)}>Remove</button></td>
                                            </tr>
                                        )
                                    }
                                })
                            }                                     
                        </tbody>
                    </table>
                   
                    <br/>
                    <br/>
                    <h2>Choose Dads</h2>

                    <table className="table table-active table-striped table-hover" style={{color: 'grey'}}>
                        <thead>
                            <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                <td>Name</td>
                                <td>Group</td>
                                <td>Number</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                members.map((member, index)=>{
                                    if(member.member.gender=='M'){
                                        //setAddValue(addValue+index)
                                        return(
                                            <tr>
                                                <td>{member.member.name}</td>
                                                <td>{member.member.yos}</td>
                                                <td>{member.phone.member_phone}</td>
                                                <td><button className='btn btn-sm btn-primary' disabled = {addIsLoading[index]} onClick={()=>addParent(member, index)}>Add</button></td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                                                           
                        </tbody>
                    </table>

                    <br/>
                    <br/>
                    <h2>Choose Moms</h2>
                    <div>
                        <table className="table table-active table-striped table-hover" style={{color: 'grey'}}>
                            <thead>
                                <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                    <td>Name</td>
                                    <td>Group</td>
                                    <td>Number</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody >
                            {
                                members.map((member, index)=>{
                                        if(member.member.gender=='F'){
                                            return(
                                                <tr>
                                                    <td>{member.member.name}</td>
                                                    <td>{member.member.yos}</td>
                                                    <td>{member.phone.member_phone}</td>
                                                    <td><button className='btn btn-sm btn-primary' disabled = {addIsLoading[index]} onClick={()=>addParent(member, index)}>{addIsLoading[index] ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Add"}</button></td>
                                                </tr>
                                            )
                                        }
                                    })
                                }                                   
                            </tbody>
                        </table>
                    </div>

                    <h3>Pair Dads and Moms</h3>
                    <div style={{overflowX: 'auto'}}>
                        <table className="table table-active table-striped table-hover" style={{color: 'grey'}}>
                            
                            <thead>
                                <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                    <td style={{minWidth: '100px', width: '10%'}}>No.</td>
                                    <td style={{width: '40%', minWidth: '150px'}}>Dad</td>
                                    <td style={{width: '40%', minWidth: '150px'}}>Mom</td>
                                    <td style={{width: '10%'}}></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    parentPairs.map((pair, index)=>{
                                        return(
                                            <>
                                            {
                                                (pair.dad && pair.mom) ?
                                                <tr>
                                                    <td>{pair.number}</td>
                                                    <td>{`${pair.dad.member.name} - ${pair.dad.member.yos}`}</td>
                                                    <td>{`${pair.mom.member.name} - ${pair.mom.member.yos}`}</td>
                                                    <td><button className='btn btn-sm btn-danger' onClick={()=>deleteParentPair(pair, index)}>Delete</button></td>
                                                </tr>                                            
                                                : null
                                            }
                                            </>
                                            
                                    
                                        )
                                    })
                                }
                                <tr>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            className='form-control'
                                            id='familyNo'
                                            value={familyNo}
                                            name='familyNo'
                                            onChange={(e)=>setFamilyNo(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            list= 'datalistOptionsdads'
                                            id="DataListdads"
                                            placeholder="Type to search..."
                                            value={selectDad}
                                            onChange={(e)=>setSelectDad(e.target.value)}
                                            name='searchValue'
                                            />
                                    </td>
                                    <td>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            list= 'datalistOptionsmoms'
                                            id="DataListmoms"
                                            value={selectMom}
                                            placeholder="Type to search..." 
                                            onChange={(e)=>setSelectMom(e.target.value)}
                                            name='searchValue'
                                            />
                                    </td>
                                    <td><button className='btn btn-primary' disabled={parentPairIsLoading} onClick={saveParentPair}>{parentPairIsLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Save"}</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <datalist id="datalistOptionsdads">
                            {parents.map((parent, index)=>{
                                if(parent.member.gender == 'M'){
                                    return <option value={index}>{parent.member.name+"-"+parent.member.yos}</option>
                                }
                            })}
                    </datalist>
                    <datalist id="datalistOptionsmoms">
                            {parents.map((parent, index)=>{
                                if(parent.member.gender == 'F'){
                                    return <option value={index}>{parent.member.name+"-"+parent.member.yos}</option>
                                }
                            })}
                    </datalist>
                </div>
            </div>
        </>
    )
}

export default Parents