import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Toast from './Toaster';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Overlay from './overlay';
import axios from 'axios';
import mode from './Mode';
import { ChangePasswordSchema } from '../schemas';

const ChangePassword = () =>{
    const [isLoading, setIsLoading] = useState(false)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/' : 'http://127.0.0.1:8000/general/';
    const user = useSelector((state)=>state.auth.user)
    const token = useSelector((state)=>state.auth.token)
    const navigate = useNavigate()

    useEffect(()=>{
        if (user == null){
            navigate('/login', {state: {reason: 'Unauthorized'}})
        }
    })

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    const onSubmit = () =>{
        setIsLoading(true)
        axios.post(baseurl+'change-password/'+user.username+'/', {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
        }, config).then((resp)=>{
            //console.log(resp.data)
            toast.success('Password changed successfully')
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
            toast.error('An error occurred')
        })
    }
    const {values, errors,  handleBlur, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: ChangePasswordSchema,
        onSubmit
    }) 

    return(
        <div className='App'>
            <Toast/>
            <Overlay/>
            <br/>
            <br/>
            <div className='main-content'>
                <div className="content-section row d-flex flex-column align-items-center justify-content-center my-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                    <div className="content-section content col-md-5 my-auto" style={{backgroundColor: "white"}}>
                
                        <form method="POST" className='forms'>
                        
                            <fieldset className='mx-auto'>
                                <legend className=" mb-4" style={{color:'#228bdf'}}>Change Password</legend>
                                    <label className='form-control-lg'>Enter current Password</label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        placeholder='Old Password'
                                        name = 'oldPassword'
                                        value = {values.oldPassword}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.oldPassword && touched.oldPassword ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.oldPassword && touched.oldPassword && <p className='error'>{errors.oldPassword}</p>}
                                
                                    <label className='form-control-lg'>New Password</label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        placeholder='New password'
                                        name = 'newPassword'
                                        value = {values.newPassword}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.newPassword && touched.newPassword ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.newPassword && touched.newPassword && <p className='error'>{errors.newPassword}</p>}
                                    <label className='form-control-lg'>Confirm Password</label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        placeholder='Confirm Password'
                                        name = 'confirmPassword'
                                        value = {values.confirmPassword}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                        style = {errors.confirmPassword && touched.confirmPassword ? {borderColor:"#fc8181"}:{}}/>
                                        { errors.confirmPassword && touched.confirmPassword && <p className='error'>{errors.confirmPassword}</p>}
                            </fieldset>
                            <div className="form-group pt-3">
                                <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                            </div>
                        </form>
                        
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ChangePassword;