import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export const BooksLoading = () =>{
    return(
        <div className='col-md-3' style={{marginBottom: '10px'}}>
            <div className='card'>
                <Skeleton sx={{ height: 250 }} animation="wave" variant="rectangular"/>
                <div className='card-body'>
                    <Skeleton sx={{ height: 50 }} width="75%" animation="wave" variant="text"/>
                    <Skeleton sx={{ height: 30 }} width="30%" animation="wave" variant="text"/>
                    <Skeleton sx={{ height: 30 }} width="50%" animation="wave" variant="text"/>
                </div>
            </div>
        </div>
    )
}