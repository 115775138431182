import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useProSidebar } from 'react-pro-sidebar'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../Slices/auth'
import CloseIcon from '@mui/icons-material/Close';

const AuthNav = () =>{
    const [isBlock, setIsBlock] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { collapseSidebar } = useProSidebar()

    const openSideBar = () =>{
      const sidebar = document.getElementsByClassName('sideBarContainer')[0]
      if(isBlock){
        sidebar.style.display = 'none'
        //sidebar.style.visibility = 'visible'
        //sidebar.style.opacity = 0.2
        setIsBlock(false)
        //collapseSidebar()
      }else{
        sidebar.style.display = 'block'
        //sidebar.style.visibility = 'hidden'
        //sidebar.style.opacity = 2
        setIsBlock(true)
        //collapseSidebar()
      }
    }

    const logout = () => {
        dispatch(logoutUser())
        navigate('/')
    }
    return (
        <header className="site-header">
            <nav className="navbar navbar-expand-md navbar-dark fixed-top" style={{backgroundColor: '#228bdf', padding: '15px'}}>
              <div className="container">
                <button className="sideToggler" type="button" onClick={()=>openSideBar()}>
                  {
                    isBlock ? <CloseIcon sx={{fontSize: 30, color: 'white'}}/>:<span className="navbar-toggler-icon"></span>
                  }
                </button>
                <a className="navbar-brand mr-4" href="#" style={{fontSize: '1.5em'}}>JKUSDA</a>
                <div className="collapse navbar-collapse" id="navbarToggle" style={{color: 'white'}}>                 
                  <div className="navbar-nav ms-auto" style={{textAlign: 'right'}}>
                    <div className="nav-item">
                        <a className="navbar-brand mr-4 display-6" href="#" role='button' onClick={()=>logout()}>Logout</a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <br/>
            <br/>
        </header>
    )
}

export default AuthNav