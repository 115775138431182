import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import Toast from './Toaster';
import TextField from '@mui/material/TextField';
import ReactLoading from 'react-loading';
import toast from 'react-hot-toast';
import mode from './Mode';
import { RetrievePasswordSchema } from '../schemas';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';

const ResetPassword = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/' : 'http://127.0.0.1:8000/general/';
    const { state } = useLocation();

    useEffect(()=>{
        if(!state){
            navigate('/reset-give-email')
        }
    })

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = () =>{
        setIsLoading(true)
        axios.post(baseurl+'retrieve-password/', {
            email: state.email,
            password: values.password1
        }).then((resp)=>{
            //console.log(resp)
            toast.success('Password has been reset successfully', {
                duration: 1200
            })
            setTimeout(()=>{
                setIsLoading(false)
                navigate('/login')
            }, 3200)
        }).catch((err)=>{
            //console.log(err)
            setIsLoading(false)
        })
    }
    const {values, errors,  handleBlur, touched, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            password1: '',
            password2: ''
        },
        validationSchema: RetrievePasswordSchema,
        onSubmit
    })

    return(
        <>
            <div className='container'>
                <Toast/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="content-section row d-flex flex-column align-items-center justify-content-center my-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                    <div className="content-section content col-md-5 my-auto" style={{backgroundColor: "white"}}>
                
                        <form method="POST" className='forms'>
                        
                            <fieldset className='mx-auto'>
                                <legend className="border-bottom mb-4" style={{color:'#228bdf'}}>Enter Verification Code</legend>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-9'>
                                            
                                            <FormControl error={errors.password1 && touched.password1} fullWidth variant="standard" required>
                                                <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name = 'password1'
                                                    value = {values.password1}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            { errors.password1 && touched.password1 && <p className='error'>{errors.password1}</p>}
                                        </div>
                                        <div className='col-md-9'>
                                            <br/>
                                            <FormControl error={errors.password2 && touched.password2} fullWidth variant="standard" required>
                                                <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name = 'password2'
                                                    value = {values.password2}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                            { errors.password2 && touched.password2 && <p className='error'>{errors.password2}</p>}
                                        </div>
                                        
                                    </div>                          
                                    
                            </fieldset>
                            <div className="form-group pt-3">
                                <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;