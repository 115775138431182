import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Overlay from "../overlay";
import Loading from '../loading';
import { logoutUser } from '../../Slices/auth';
import mode from '../Mode';

const NoFamily = ()=>{
    return(
        <div style={{textAlign: 'center'}} className='content'>
            <br/>
            <h3 style={{color: '#fc8181'}}>You don't belong to any Family</h3>
            <br/>
            <h5>This might be because of one of the following Reasons:</h5>
            <ul className='list-group list-group-flush' style={{background: 'transparent', border: 'none'}}>
                <li className='list-group-item' style={{background: 'transparent', border: 'none'}}>
                    You have not reported for the current Session<br/>
                    <Link to='/report-session' style={{textDecoration: 'none'}}>Report</Link>
                </li>
                <li className='list-group-item' style={{background: 'transparent', border: 'none'}}>Families are not yet out for the current Session</li>
                <li className='list-group-item' style={{background: 'transparent', border: 'none'}}>
                    Unexpected error occurred. Send a messsage to us on the Contact us Page<br/>
                    <Link to='/contact-us' style={{textDecoration: 'none'}}>Contact Us</Link>
                </li>
            </ul>
        </div>
    )
}

const MyFamily = () =>{
    const member = useSelector((state)=>state.auth.member)
    //console.log(member)
    const token = useSelector((state)=>state.auth.token)
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [indexList, setIndexList] = useState([])
    const [noFamily, setNoFamily] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/nurturing/': 'http://127.0.0.1:8000/nurturing/';
    const dispatch = useDispatch()

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        if (member == null){
            navigate('/login', {state: {reason: 'Unauthorized'}})
        }
        setIsLoading(true)
        axios.get(`${baseurl}my-family/${member.id}`, config).then((resp)=>{
            setData(resp.data)
            setIndexList(resp.data.index)
            if(resp.data.index.length > 0){
                setNoFamily(false)
            }
            setTimeout(()=>{setIsLoading(false)}, 2000)
        }).catch((err)=>{
            console.error(err)
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            setTimeout(()=>{setIsLoading(false)}, 2000)
            
        })
    }, [])

    return(
        <div id='App'>
            <Overlay/> 
            <div className="main-content">
                <br/>
                <h3 style={{color: '#228bdf'}} className='display-3'>My Family</h3>
                {
                    isLoading ? <Loading />
                    :
                        noFamily ? <NoFamily />
                        :               
                        <table className="table table-secondary table-bordered table-striped table-hover" style={{color: 'grey'}}>
                                <thead>
                                    <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                        <td>NAME</td>
                                        <td>GROUP</td>
                                        <td>NUMBER</td>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        data.parents?
                                        <>
                                            {
                                                data.parents.dad?
                                                    <tr>
                                                        <td>{data.parents.dad.member.name} - Dad</td>
                                                        <td>{data.parents.dad.member.yos}</td>
                                                        <td>{data.parents.dad.phone.member_phone}</td>
                                                    </tr>
                                                :null
                                            }
                                            {
                                                data.parents.mom?
                                                    <tr>
                                                        <td>{data.parents.mom.member.name} - Mom</td>
                                                        <td>{data.parents.mom.member.yos}</td>
                                                        <td>{data.parents.mom.phone.member_phone}</td>
                                                    </tr>
                                                :null

                                            }
                                            
                                        </>:""
                                    }
                                    {
                                        indexList.map((index, x)=>{
                                            return(
                                                <tr>
                                                    <td>{data.name[Number(x)]}</td>
                                                    <td>{data.intake[Number(x)]}</td>
                                                    <td>{data.phone_number[Number(x)]}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                
                                </tbody>
                        </table>
                    }
                
            </div>
        </div>
    )
}

export default MyFamily