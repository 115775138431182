import React from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import ReactLoading from 'react-loading'
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast'
import Toast from './Toaster'

import Overlay from "./overlay";

const ContactUs = () =>{
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () =>{
        console.log('submitting')
        //sending the ticket_id via email
        setIsLoading(true)
        var templateParams = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            subject: values.subject,
            message: values.message
        }

        emailjs.send('service_5yfw0xh', 'template_v6k5uhh', templateParams, 'Y_h02AB2ElujhmJ37')
        .then((result) => {
            //console.log(result.text);
            setIsLoading(false)
            toast.success('Email Sent', {
                duration: 2000,
                position: 'top-center'
            })
        }, (error) => {
            console.log(error.text);
        });
    }
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            subject: '',
            message: ''
        },
        onSubmit
    })
    return (
        <div className="App">
            <Overlay/>
            <Toast/>
            <div className="main-content">
            <br/>
            <br/>
            <br/>
            <div className='container content-section'>
            <div className="row d-flex flex-column align-items-center justify-content-center m-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                <div className="content-section content col-md-7 m-auto" style={{backgroundColor: "white"}}>
                    <form method="POST" className='forms'>
                        <fieldset>
                            <legend className="mb-4" style={{color:'#228bdf'}}>Contact Us</legend>                              
                                <div className="row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4" className='form-control-lg'>First Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='first_name'
                                            value = {values.first_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="First Name"
                                            style = {errors.first_name && touched.first_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.first_name && touched.first_name && <p className='error'>{errors.first_name}</p>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Last Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='last_name'
                                            value = {values.last_name}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Last Name"
                                            style = {errors.last_name && touched.last_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.last_name && touched.last_name && <p className='error'>{errors.last_name}</p>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4" className='form-control-lg'>Email</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name='email'
                                            value = {values.email}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            placeholder="Valid Email"
                                            style = {errors.last_name && touched.last_name ? {borderColor:"#fc8181"}:{}}/>
                                            { errors.last_name && touched.last_name && <p className='error'>{errors.last_name}</p>}
                                    </div>
                                </div>
                                <label className='form-control-lg'>Subject</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name = 'subject'
                                    value = {values.subject}
                                    onChange = {handleChange}
                                    onBlur = {handleBlur}
                                    style = {errors.subject && touched.subject ? {borderColor:"#fc8181"}:{}}/>
                                    { errors.subject && touched.subject && <p className='error'>{errors.subject}</p>}
                                
                                <label className='form-control-lg'>Message</label>
                                <textarea
                                    type='text-area'
                                    className='form-control'
                                    name = 'message'
                                    value = {values.message}
                                    onChange = {handleChange}
                                    onBlur = {handleBlur}
                                    style = {errors.message && touched.message ? {borderColor:"#fc8181"}:{}}/>
                                    { errors.message && touched.message && <p className='error'>{errors.message}</p>}
                                
                        </fieldset>
                        <div className="form-group" style={{margin: '6px'}}>
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Send"}</button>
                        </div>
                    </form>
                </div>
            </div>
            <br/>
        </div>  
                
            </div>
        </div>
    )
}

export default ContactUs