import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Overlay from '../../overlay';
import Toast from '../../Toaster';
import mode from '../../Mode';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import ReactLoading from 'react-loading';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import Loading from '../../loading';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../Slices/auth';
import { useNavigate } from 'react-router-dom';
import { CreateBookSchema } from '../../../schemas';

const BooksManager = () =>{
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [borrowStateLoading, setBorrowStateLoading] = useState(false)
    const [books, setBooks] = useState([])
    const [borrowers, setBorrowers] = useState([])
    const [buyers, setBuyers] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [file,setFile] = useState('')
    const token = useSelector((state)=>state.auth.token)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/publishing/': 'http://127.0.0.1:8000/publishing/';

    const config = {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    }

    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        axios.get(baseurl+'books', config).then((resp)=>{
            //console.log(resp.data)
            setBooks(resp.data.books)
            setBorrowers(resp.data.borrowers)
            setBuyers(resp.data.buyers)
            setIsLoaded(true)
        }).catch((err)=>{
            setIsLoaded(true)
            console.log(err)
            if (err.response && err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            toast.error('Cannot fetch data', {
                position: 'bottom-left',
                duration: 3000
            })
        })
    }, [])

    const onSubmit = () => {
        if(file){
            setIsLoading(true)
            let formData = new FormData();
            formData.append('thumbnail', file)
            formData.append('title', values.title)
            formData.append('no_of_copies', values.no_of_copies)
            formData.append('price', values.price)

            axios.post(baseurl+'books/', formData, config).then((resp)=>{
                //console.log(resp);
                setBooks([...books, resp.data])
                toast.success('Book has been created');
                setIsLoading(false);
            }).catch((err)=>{
                console.log(err);
                setIsLoading(false);
                toast.error('An Error occurred')
            })
        }else{
            toast.error('Please upload a picture of the book')
        }

    }
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            title: '',
            no_of_copies: '',
            price: ''
        },
        validationSchema: CreateBookSchema,
        onSubmit
    })

    const deleteBook = (index) =>{
        setDeleteLoading(true)
        axios.delete(baseurl+'books/delete/'+books[index].id+'/', config).then((resp)=>{
            //console.log(resp);
            let tempBooks = [...books]
            let removedBook = tempBooks.splice(index, 1)
            setBooks(tempBooks)
            setDeleteLoading(false)
        }).catch((err)=>{
            console.log(err);
            setDeleteLoading(false)
        })
    }

    const toggleBorrowStatus = (index, status) =>{
        setBorrowStateLoading(true)
        axios.put(baseurl + 'borrow/'+books[index].id+'/', {status: status}, config).then((resp)=>{
            //console.log(resp)
            let tempBorrowers = [...borrowers]
            tempBorrowers[index].returned = status
            setBorrowers(tempBorrowers)
            setBorrowStateLoading(false)

        }).catch((err)=>{
            console.log(err)
            if (err.response && err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            toast.error('An error occurred')
            setBorrowStateLoading(false)
        })
    }

    const deleteBorrower = (index) =>{
        setBorrowStateLoading(true)
        axios.delete(baseurl + 'borrow/'+books[index].id+'/', config).then((resp)=>{
            //console.log(resp)
            let tempBorrowers = [...borrowers]
            let removedBorrower = tempBorrowers.splice(index, 1)
            setBorrowers(tempBorrowers)
            setBorrowStateLoading(false)
        }).catch((err)=>{
            if (err.response && err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            setBorrowStateLoading(false)
        })
    }
    return(
        <div id='App'>
            <Toast/>
            <Overlay/>
            <div className='main-content'>
                <br/>
                <h5 className='display-6'>Books Manager</h5>
                <div className='row mx-auto' style={{width: '100%'}}>
                    <div className='mx-auto ml-10 card content col-md-7'>
                    <form method="POST" className='forms'>
                                <fieldset>
                                    <legend className="mb-2" style={{color:'#228bdf'}}>Add a Book</legend>                              
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputEmail4" className='form-control-lg'>Title</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name='title'
                                                    value = {values.title}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    placeholder="Book Title"
                                                    style = {errors.title && touched.title ? {borderColor:"#fc8181"}:{}}/>
                                                    { errors.title && touched.title && <p className='error'>{errors.title}</p>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputPassword4" className='form-control-lg'>Thumbnail</label>
                                                <input 
                                                    type = 'file'
                                                    required
                                                    className="form-control"
                                                    name = 'res'
                                                    
                                                    onChange={(e)=>setFile(e.target.files[0])}/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label htmlFor="inputEmail4" className='form-control-lg'>No of Copies</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name = 'no_of_copies'
                                                    value={values.no_of_copies}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    placeholder="Number of Copies"
                                                    style = {errors.no_of_copies && touched.no_of_copies ? {borderColor:"#fc8181"}:{}}/>
                                                    { errors.no_of_copies && touched.no_of_copies && <p className='error'>{errors.no_of_copies}</p>}

                                            </div>
                                            <div className='col-md-6'>
                                                <label htmlFor="inputEmail4" className='form-control-lg'>Price</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name = 'price'
                                                    value={values.price}
                                                    onChange = {handleChange}
                                                    onBlur = {handleBlur}
                                                    placeholder="Book price"
                                                    style = {errors.price && touched.price ? {borderColor:"#fc8181"}:{}}/>
                                                    { errors.price && touched.price && <p className='error'>{errors.price}</p>}
                                            </div>
                                            
                                            
                                        </div>
                                        
                                </fieldset>
                                <div className="form-group" style={{margin: '6px'}}>
                                    <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                                </div>
                            </form>
                    </div>
                </div>
                <div>
                        <br/>
                        <h4 className='display-6'>Available Books</h4>
                        <div style={{overflowX: 'auto'}}>
                            {
                                isLoaded ? 
                                    <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                    <thead style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                        <tr>
                                            <td>Title</td>
                                            <td>Copies</td>
                                            <td>Price</td>                                            
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {
                                                    books.map((book, index)=>{
                                                        return(
                                                            <tr key={index}>
                                                                <td>{book.title}</td>
                                                                <td>{book.no_of_copies}</td>
                                                                <td>{book.price}</td>
                                                                <td><button disabled={deleteLoading} className='btn btn-danger' onClick={()=>deleteBook(index)}>Delete</button></td>                                                                
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                </table>: <Loading/>
                            }
                        </div>
                        <br/>
                        <h4 className='display-6'>Borrowers</h4>
                        <div style={{overflowX: 'auto'}}>
                            {
                                isLoaded ? 
                                    <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                    <thead>
                                        <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                            <td>Name</td>
                                            <td>Phone</td>
                                            <td>Email</td>
                                            <td>Book</td>
                                            <td>Picked</td>
                                            <td>Returned</td>    
                                            <td></td>                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {
                                                    borrowers.map((borrower, index)=>{
                                                        return(
                                                            <tr key={index}>
                                                                <td>{borrower.authMember.member.name}</td>
                                                                <td>{borrower.authMember.phone.member_phone}</td>
                                                                <td>{borrower.authMember.user.email}</td>
                                                                <td>{borrower.book.title}</td>
                                                                <td>
                                                                    {borrower.returned == 0 ? <button disabled={borrowStateLoading} onClick={()=>toggleBorrowStatus(index, 1)} className='btn btn-primary'>No</button>:
                                                                        <button disabled={borrowStateLoading} onClick={()=>toggleBorrowStatus(index, 0)} className='btn btn-info'>Yes</button>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {borrower.returned == 2 ? <button disabled={borrowStateLoading} onClick={()=>toggleBorrowStatus(index, 1)} className='btn btn-success'>Yes</button>:
                                                                        <button disabled={borrowStateLoading} onClick={()=>toggleBorrowStatus(index, 2)} className='btn btn-secondary'>No</button>
                                                                    }
                                                                </td>
                                                                <td><button disabled={borrowStateLoading} className='btn btn-danger' onClick={()=>deleteBorrower(index)}>Delete</button></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                </table>: <Loading/>
                            }
                        </div>
                        <h4 className='display-6'>Buyers</h4>
                        <div style={{overflowX: 'auto'}}>
                            {
                                isLoaded ? 
                                    <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                    <thead>
                                        <tr style={{color: '#29b8b0', fontSize: '1.2em'}}>
                                            <td>Name</td>
                                            <td>Phone</td>
                                            <td>Email</td>
                                            <td>Book</td>
                                            <td>Copies</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                {
                                                    buyers.map((buyer, index)=>{
                                                        return(
                                                            <tr key={index}>
                                                                <td>{buyer.authMember.member.name}</td>
                                                                <td>{buyer.phone_number}</td>
                                                                <td>{buyer.email}</td>
                                                                <td>{buyer.book}</td>
                                                                <td>{buyer.copies}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                </table>: <Loading/>
                            }
                        </div>
                </div>
            </div>
        </div>
    )
}

export default BooksManager;
