import React, {useState} from "react";
import mode from "./Mode";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormik } from "formik";
import toast from 'react-hot-toast'
import axios from "axios";
import Toast from "./Toaster";
import ReactLoading from 'react-loading';
import { PaymentSchema } from "../schemas";

const Home = () =>{

    const [isLoading, setIsLoading] = useState(false)

    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/' : 'http://127.0.0.1:8000/';

    const onSubmit = () => {  
        setIsLoading(true)      
        var phoneNu = values.phone
        var updated_num = "+254" + phoneNu[1]+ phoneNu[2]+ phoneNu[3]+ phoneNu[4]+ phoneNu[5]+ phoneNu[6]+ phoneNu[7]+ phoneNu[8]+ phoneNu[9]
        axios.post(baseurl+'payments/checkout/', {
            phone_number: updated_num,
            amount: Number(values.amount),
            reference: values.contribution,
            description: values.contribution,
            billref: values.contribution
        }).then((resp)=>{
            toast.success('An Mpesa prompt has been sent to your phone', {
                position: 'top-right',
                duration: 7500,
                style: {
                    background: 'greenYellow',
                    color: '#fff',
                    fontSize: '1.15em'
                },
            })
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            toast.error('An error occured')
        })
    }

    const {values, errors,  handleBlur, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            phone: '',
            contribution: '',
            amount: ''
        },
        validationSchema: PaymentSchema,
        onSubmit
    })

    return(
        <>
            <Toast/>
            <section id="home" className="templatemo-home">
                <div className="container">
                    <div className='overlay'></div>
                    <div className= "d-flex flex-column align-items-center justify-content-center mx-auto">                        
                        <div><h1 style={{textAlign: 'center', color: 'white'}}>JKUSDA PORTAL</h1></div>
                    </div>
                </div>
            </section>
            <div className="container">
                <br/>
                <br/>
                <br/>
                <div className="d-flex flex-column align-items-center justify-content-center mx-auto" style={{textAlign: 'center'}}>
                    <h3 style={{color: '#228bdf'}}>Jomo Kenyatta University Seventh Day Adventist Church</h3>
                    <div className="text-center mx-auto">
                        <img className="rounded-circle account-img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrtMa7rmo58_xAfQDzCb99BTLJhbFjo7J5vQ&usqp=CAU" style={{width: '50%', height: '50%', marginLeft: '27px'}}/>
                    </div>
                </div> 
                <br/>
                <br/>               
            </div>
            <section id="home" className="templatemo-give">
                <div className="container">
                    <div className='overlay'></div>
                    <div className="col-md-12 col-sm-10" style={{textAlign: 'center'}}>
                        <h1 style={{textAlign: 'center', color:'white'}}><b>Give for God's course</b></h1>
                        <h4 style={{textAlign: 'center', color:'white'}}><i>"Each of you should give what you have decided in your heart to give, not reluctuntly or under compulsion, for God loves a cheerful giver"</i></h4>
                        <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Give
                        </button>
                    </div>
                </div>
            </section>
            <section className="footer">
                <h5 style={{color: 'white'}}>&copy; 2017 - 2024 JKUSDA Church</h5>
            </section>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Give</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="row justify-content-center">
                                <div className="form-group col-md-8">
                                       
                                        <FormControl fullWidth error={errors.contribution && touched.contribution} required variant="standard">
                                            <InputLabel id="demo-simple-select-standard-label-1">Contribution</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name='contribution'
                                                value = {values.contribution}
                                                onChange = {handleChange}
                                                
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="Tithe">Tithe</MenuItem>
                                                <MenuItem value="Offering">Offering</MenuItem>
                                                <MenuItem value="Mission">Mission</MenuItem>
                                                <MenuItem value="Camp Expense">Camp Expense</MenuItem>
                                                <MenuItem value="Camp Offering">Camp Offering</MenuItem>
                                            </Select>
                                        </FormControl>
                                        { errors.contribution && touched.contribution && <p className='error'>{errors.contribution}</p>}
                                       
                                </div>
                                <div className="col-md-8">
                                    <TextField 
                                        fullWidth required
                                        style={{marginTop: '6px', marginBottom: '6px'}}
                                        error={errors.phone && touched.phone}
                                            id="standard-basic-1" 
                                            label="Phone Number" 
                                            variant="standard" 
                                            name = 'phone'
                                            value = {values.phone}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                    />
                                    { errors.phone && touched.phone && <p className='error'>{errors.phone}</p>}
                                </div>
                                <div className="col-md-8">
                                    <TextField 
                                        fullWidth required 
                                        error={errors.amount && touched.amount}
                                        type='number'
                                        min='1'
                                            id="standard-basic" 
                                            label="Amount" 
                                            variant="standard" 
                                            name = 'amount'
                                            value = {values.amount}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                    />
                                    { errors.amount && touched.amount && <p className='error'>{errors.amount}</p>}
                                </div>

                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-primary" type="submit" disabled={isLoading} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home