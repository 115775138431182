import React from 'react'
import ReactLoading from 'react-loading'

const AuthLoading = () =>{
    return(
        <div className='row justify-content-around'>
            <div className='col-md-6' style={{textAlign: 'center'}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <button className='authLoadingButton'><ReactLoading type="spin" color="grey" height={150} width={150} /></button>
            </div>
        </div>
    )
}

export default AuthLoading;