import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomeNav from './components/HomeNavbar';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import { Provider } from 'react-redux';
import store from './store';
import MemberRegister from './components/member';
import Dashboard from './components/dashboard';
import ContactUs from './components/contactUs';
import ReportSession from './components/Report';
import MyFamily from './components/Nurturing/myFamily';
import AllFamilies from './components/Nurturing/allFamilies';
import GenerateFamilies from './components/Nurturing/Admin/GenerateFamilies';
import Parents from './components/Nurturing/Admin/Parents';
import Test from './components/test';
import Role from './components/Admin/Roles';
import AuthNav from './components/AuthNav';
import BuyLesson from './components/SabbathScl/buyLesson';
import Subscribers from './components/SabbathScl/Admin/subscribers';
import Tickets from './components/Tickets/tickets';
import { useSelector, useDispatch } from 'react-redux';
import Error from './components/Error404';
import UploadResources from './components/Admin/uploadResources';
import CreateTicket from './components/Tickets/Admin/createTicket';
import Resources from './components/resources';
import ManageTicket from './components/Tickets/Admin/manageTicket';
import MemberContribution from './components/Treasury/MemberContribution';
import axios from 'axios';
import { useState, useEffect } from 'react';
import mode from './components/Mode';
import AuthLoading from './components/AuthLoading';
import { logoutUser } from './Slices/auth';
import Teachers from './components/SabbathScl/Admin/Teachers';
import MakePayments from './components/Treasury/MakePayments';
import MyAccount from './components/MyAccount';
import ChangePassword from './components/ChangePassword';
import GiveEmail from './components/reset_give_email';
import GiveCode from './components/reset_code';
import ResetPassword from './components/reset_password';
import BooksManager from './components/publishing/Admin/booksManager';
import BooksList from './components/publishing/booksList';
import Gallery from './components/gallery';

function App() {
  const dispatch = useDispatch()
  const user = useSelector((state)=>state.auth.user)
  const isAuthenticated = useSelector((state)=>state.auth.isAuthenticated)
  const token = useSelector((state)=>state.auth.token)
  const [isLoaded, setIsLoaded] = useState(false)
  const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/': 'http://127.0.0.1:8000/general/';

  const config = {
    headers: {
        'Content-Type':'application/json'
    }
  }
  if(token){
    config.headers['Authorization'] = `Token ${token}`;
  }

  useEffect(()=>{
    setIsLoaded(false)
    if(user && isAuthenticated){
      axios.get(baseurl + 'check-authorization/', config).then((resp)=> {
        setIsLoaded(true)
      }).catch((err)=>{
        dispatch(logoutUser())
        setIsLoaded(true)
      })
    }else{
      setIsLoaded(true)
    }
  }, [])

  return (
      <Router>
        {
          isAuthenticated ? <AuthNav/>: <HomeNav/>
        }
        <br/>
        <Routes>
          <Route exact path='/' element = {(user == null) ? <Home/>:isLoaded ? isAuthenticated ? <Dashboard/>: <Home/>: <AuthLoading/>} />
          <Route exact path='/home' element={<Home/>}/>
          <Route exact path='/register' element={<Register/>}/>
          <Route exact path='/login' element={<Login/>}/>
          <Route exact path='/member-register' element={<MemberRegister/>}/>
          <Route exact path='/dashboard' element={<Dashboard/>}/>
          <Route exact path='/contact-us' element={<ContactUs/>}/>
          <Route exact path='/report-session' element={<ReportSession/>} />
          <Route exact path='/my-family' element={<MyFamily/>}/>
          <Route exact path='/all-families' element={<AllFamilies/>}/>
          <Route exact path='/admin-generate-families' element={<GenerateFamilies/>} />
          <Route exact path='/admin-filter-parents' element={<Parents/>} />
          <Route exact path='/admin-general-roless' element={<Role/>}/>
          <Route exact path='/admin-upload-resources' element={<UploadResources/>}/>
          <Route exact path='/resources' element={<Resources/>}/>
          <Route exact path='/buy-lesson' element={<BuyLesson/>} />
          <Route exact path='/lesson-subscribers' element={<Subscribers/>}/>
          <Route exact path='/teachers' element={<Teachers/>}/>
          <Route exact path='/create-ticket' element = {<CreateTicket/>}/>
          <Route exact path='/tickets' element={<Tickets/>}/>
          <Route exact path='/manage-ticket' element = {<ManageTicket/>}/>
          <Route exact path='/my-contributions' element = {<MemberContribution/>}/>
          <Route exact path='/make-payments' element = {<MakePayments/>}/>
          <Route exact path='/my-account' element={<MyAccount/>}/>
          <Route exact path='/change-password' element={<ChangePassword/>}/>
          <Route exact path='/reset-give-email' element={<GiveEmail/>}/>
          <Route exact path='/reset-give-code' element={<GiveCode/>}/>
          <Route exact path='/reset-password' element={<ResetPassword/>}/>
          <Route exact path='/books-manager' element={<BooksManager/>}/>
          <Route exact path='/publishing' element = {<BooksList/>}/>
          <Route exact path='/gallery' element = {<Gallery/>}/>
          <Route exact path='/test' element={<Test/>} />
          <Route path="*"element={<Error/>}/>
        </Routes>
      </Router>
  );
}

export default App;
