import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Overlay from '../overlay'
import ReactLoading from 'react-loading'
import axios from 'axios'
import { logoutUser } from '../../Slices/auth'
import mode from '../Mode'

const Role = () =>{
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [roleUser, setRoleUser] = useState("")
    const [role, setRole] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const userType = useSelector((state)=>state.auth.userType)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general': 'http://127.0.0.1:8000/general';

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const token = useSelector((state)=>state.auth.token)
    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }
    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }
    useEffect(()=>{
        if(!(userType.includes('Super Admin'))){
            navigate('/dashboard')
        }
        axios.get(`${baseurl}/roles/`, config).then((resp)=>{
            console.log(resp.data)
            setUsers(resp.data.users)
            setRoles(resp.data.roles)
        }).catch((err)=>{
            console.error(err)
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
        })
    }, [])

    const handleSubmit = () =>{
        setIsLoading(true)
        axios.post(`${baseurl}/roles/`, {
            username: roleUser,
            role: role
        }, config).then((resp)=>{
            console.log(resp)
            setRoles(resp.data)
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
        })
    }

    return(
        <>
            <div id='App'>
                <Overlay/>
                <div className='main-content'>
                    <br/>
                    <div className='mx-auto card mb-1 rolesBody content'>
                        <h3>Assign Role</h3>
                        <form className="forms row mx-auto" style={{width: '100%'}}>
                            <div className='form-group col-md-4 mx-auto'>
                                <label>Username</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    list='usersdatalist'
                                    value = {roleUser}
                                    name='roleUser'
                                    placeholder='Type username...'
                                    onChange={(e)=>setRoleUser(e.target.value)}

                                />
                                <datalist id='usersdatalist'>
                                    {
                                        users.map((user, index)=>{
                                            return(
                                                <option value={user.username}>{user.username}</option>
                                            )
                                        })
                                    }
                                </datalist>
                            </div>
                            <div className='form-group col-md-4 mx-auto'>
                                <label>Role</label>
                                <select
                                    className="form-select" 
                                    name='gender'
                                    value = {role}
                                    name='role'
                                    placeholder="Gender"
                                    onChange={(e)=>setRole(e.target.value)}
                                >
                                    <option value=""></option>
                                    <option value="Super Admin">Super Admin</option>
                                    <option value="Nurturing Admin">Nurturing Admin</option>
                                    <option value="Publishing Admin">Publishing Admin</option>
                                    <option value="Sabbath School Admin">Sabbath School Admin</option>
                                    <option value="Treasury Admin">Sabbath School Admin</option>
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <br/>
                                    <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Save"}</button>
                            </div>
                        </form>
                        <br/>
                    </div>
                    <div>
                        <br/>
                        <h4 className='display-6'>Current Roles</h4>
                        <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                            <thead>
                                <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                    <td>Name</td>
                                    <td>Role</td>
                                    <td>Start Date</td>
                                    <td>State</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    roles.map((role, index)=>{
                                        return(
                                            <tr>
                                                <td>{role.user.username}</td>
                                                <td>{role.role}</td>
                                                <td>{role.start_date}</td>
                                                <td>{role.is_active?"Active": "Inactive"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                     </div>
                </div>
            </div>
        </>
    )
}

export default Role;