import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import Overlay from "./overlay";
import Loading from './loading';
import mode from './Mode';
import { logoutUser } from '../Slices/auth';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const NoResources = ()=>{
    return(
        <div style={{textAlign: 'center'}} className='content'>
            <br/>
            <h3 style={{color: '#fc8181'}}>No Resources</h3>
            <br/>
        </div>
    )
}

const Resources = ()=>{
    const [resources, setResources] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [haveResources, setHaveResources] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org': 'http://127.0.0.1:8000';
    const token = useSelector((state)=>state.auth.token)
    const user = useSelector((state)=>state.auth.user)
    const config = {
        headers: {
            'Content-Type':'multpart/form-data'
        }
    }
    const downloadConfig = {
        headers: {
            'Content-Type':'application/pdf'
        }
    }

    if(token){
        config.headers['Authorization'] = `Token ${token}`;
        downloadConfig.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        //if no logged in user
        if (user == null){
            toast.error('Unauthorized, kindly login', {
                position: 'bottom-left',
                duration: 7000
            })
            dispatch(logoutUser())
            navigate('/login')
        }

        //make the request if user exists
        axios.get(baseurl+'/general/resources/', config).then((res)=>{
            setResources(res.data)
            setIsLoading(false)
            if (res.data.length > 0){
                setHaveResources(true)
            }
        }).catch((err)=>{
            // if user session is expired in server
            if(err.response.status == 401){
                toast.error('Unauthorized, kindly login', {
                    position: 'bottom-left',
                    duration: 7000
                })
                dispatch(logoutUser())
                navigate('/login')
            }
            setIsLoading(false)
        })
    }, [])

    const downloadResource = (url,name)=>{
        axios.get(baseurl + url,{
            responseType: 'blob' // Set the response type to 'blob'
          }, downloadConfig).then((response)=>{
            // Create a temporary URL for the downloaded file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            //console.log(response.data)

            // Create a link element and initiate the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name+'.pdf');
            document.body.appendChild(link);
            link.click();

            // Clean up the temporary URL
            window.URL.revokeObjectURL(url);
        })
    }

    return(
        <div id='App'>
            <Overlay/>
            <div className='main-content'>
                <br/>
                <h3 style={{color: '#228bdf'}} className='display-3'>Resources</h3>
                {
                    isLoading? <Loading/>:
                    haveResources ? 
                        <table className="table table-bordered table-striped table-secondary table-hover" style={{color: 'grey'}}>
                                <thead>
                                    <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                        <td>Name</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        resources.map((resource, index)=>{
                                            return(
                                                <tr>
                                                    <td>{resource.name}</td>
                                                    <td><button className='btn btn-sm btn-primary' onClick={()=>downloadResource(resource.file, resource.name)}>Download</button></td>
                                                    
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        :<NoResources/>

                }
            </div>

        </div>
    )
}

export default Resources