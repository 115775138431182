import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setUser, setToken, setUserType } from '../Slices/auth'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactLoading from 'react-loading'

import { registerSchema } from '../schemas'
import { useNavigate } from 'react-router-dom'
import Toast from './Toaster'
import toast from 'react-hot-toast'
import mode from './Mode';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';

const Register = () =>{
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/': 'http://127.0.0.1:8000/general/';
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = () => {
        setIsLoading(true)
        axios.post(baseurl + 'register/', {
            username: values.email,
            email: values.email,
            password: values.password1,
        }).then((response)=>{
            dispatch(setUser(response.data.user));
            dispatch(setToken(response.data.token));
            toast.success('Successful Registration', {
                position: 'bottom-left',
                duration: 3000
            })
            setTimeout(()=>{navigate('/member-register')}, 3000)
        }).catch((error)=>{
            console.log(error);
            setIsLoading(false)
            if (error.response.data.Error){
                toast.error(error.response.data.Error)
            }else{
                toast.error('An Error occurred')
            }
           
        })

    }

    const {values, errors,  handleBlur, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            email: '',
            password1: '',
            password2: ''
        },
        validationSchema: registerSchema,
        onSubmit
    })
    return(
        <>
            <Toast/>
            <br/>
            <br/>
            <br/>
            <div className='container content-section'>
            <div className="row d-flex flex-column align-items-center justify-content-center m-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                <div className="content-section content col-md-5 m-auto" style={{backgroundColor: "white"}}>
                    <form method="POST" className='forms'>
                        <fieldset>
                            <legend className="border-bottom mb-4" style={{color:'#228bdf'}}>Student Register</legend>
                            <form className='row justify-content-center'>
                                <div className='col-md-9'>
                                    
                                    <TextField 
                                        fullWidth required 
                                        error={errors.email && touched.email}                                       
                                        id="standard-basic" 
                                        label="Email" 
                                        variant="standard" 
                                        name = 'email'
                                        value = {values.email}
                                        onChange = {handleChange}
                                        onBlur = {handleBlur}
                                    />
                                    { errors.email && touched.email && <p className='error'>{errors.email}</p>}
                                </div>                                
                                
                                <div className='col-md-9'>
                                    <br/>
                                    <FormControl error={errors.password1 && touched.password1} fullWidth variant="standard" required>
                                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                        <Input
                                            id="standard-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            name = 'password1'
                                            value = {values.password1}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    { errors.password1 && touched.password1 && <p className='error'>{errors.password1}</p>}
                                </div>
                                <div className='col-md-9'>
                                    <br/>
                                    <FormControl error={errors.password2 && touched.password2} fullWidth variant="standard" required>
                                        <InputLabel htmlFor="standard-adornment-password">COnfirm Password</InputLabel>
                                        <Input
                                            id="standard-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            name = 'password2'
                                            value = {values.password2}
                                            onChange = {handleChange}
                                            onBlur = {handleBlur}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    { errors.password2 && touched.password2 && <p className='error'>{errors.password2}</p>}
                                </div>
                                
                            </form>
                        </fieldset>
                        <div className="form-group pt-3">
                            <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Register"}</button>
                        </div>
                    </form>
                    <div className="pt-3">
                        <small className="text-muted">
                            Have An Account?<Link className="ml-2" to='/Login'>Login</Link>
                        </small>
                    </div>
                </div>
            </div>
            <br/>
        </div>
        </>
    )
}

export default Register