import React, {useEffect, useState} from 'react';
import mode from '../Mode';
import { useSelector } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';
import Toast from '../Toaster';
import Overlay from '../overlay';
import { BooksLoading } from '../utils';
import { logoutUser } from '../../Slices/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BooksList = () =>{
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const member = useSelector((state)=>state.auth.member)
    const token = useSelector((state)=>state.auth.token);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const skeletons = [1,2,3,4,5,6]
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/publishing/': 'http://127.0.0.1:8000/publishing/';
    const media_baseurl = mode ? 'https://backend.portal.jkusdachurch.org/': 'http://127.0.0.1:8000/';

    const config = {
        headers: {
            'Content-Type':'multpart/form-data'
        }
    }

    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    
    useEffect(()=>{
        axios.get(baseurl+'books/get/', config).then((resp)=>{
            setBooks(resp.data);
            setIsLoaded(true);
        }).catch((err)=>{
            setIsLoaded(true);
        })
    }, [])

    const borrow = (bookId) =>{
        setIsLoading(true)
        axios.post(baseurl + 'borrow/', {
            authMember: member.id,
            book: bookId
        }).then((resp)=>{
            console.log(resp.data);
            toast.success('Borrow request has been sent');
            setIsLoading(false)
        }).catch((err)=>{
            if (err.response && err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
            if (err.response && err.response.status == 403){
                toast.error('You have already borrowed the book. Contact Publishing department')
            }else{
                toast.error('An error occurred')
            }
            
            console.log(err)
            setIsLoading(false)
            
        })
    }

    const buy = (bookid) => {
        toast.error('This feature will soon be available')
    }
    return(
        <div id='App'>
            <Toast/>
            <Overlay/>
            <div className='main-content'>
                <br/>
                <div className='row mx-auto'>
                    {
                        isLoaded ? books.length == 0 ? <h1>No Books Currently</h1> :
                        books.map((book, index)=>{
                            return(
                                <div className='col-md-3' style={{marginBottom: '10px'}} key={index}>
                                    <div className='card'>
                                        <img className='card-img-top bookImg' src={media_baseurl+book.thumbnail}/>
                                        <div className='card-body' style={{textAlign: 'left'}}>
                                            <h5 className='card-title'>{book.title}</h5>
                                            <p style={{fontSize: '1.1em'}}> {book.no_of_copies} Copies</p>
                                            <div className='row col-12'>
                                                <button  role= 'button' disabled = {isLoading} className="col-5 btn btn-outline-primary" onClick={()=>borrow(book.id)}>Borrow</button>
                                                <button  role= 'button' disabled = {isLoading} className="col-5 btn btn-outline-success" style={{marginLeft: '3px'}} onClick={()=>buy(book.id)}>Buy</button>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        skeletons.map((sk, key)=>{
                            return(
                                <BooksLoading/>
                            )
                        })

                    }
                </div>
            </div>
        </div>
    )
}

export default BooksList;