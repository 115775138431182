import * as yup from 'yup'

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const reg_numberReg = /^[a-zA-Z]{3}\d{3}-\d{4}\/\d{4}$/g;
function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
}

export const registerSchema = yup.object().shape({
    email: yup.string().email("Please Enter a valid email").matches(emailReg, {message: "Please enter a valid email"}).required("Required"),
    password1: yup.string().required("Required").min(7, "Minimum of 7 charachters"),
    password2: yup.string().required("Required").oneOf([yup.ref('password1')], 'Passwords must match'),

})

export const memberRegisterSchema = yup.object().shape({
    first_name: yup.string().required("Required").min(3, "🤔 Invalid name"),
    last_name: yup.string().required("Required").min(3, "🤔 Invalid name"),
    course: yup.string().required("Required").min(3, "Minimum 3 characters"),
    intake: yup.string().required("Required").min(3),
    baptised: yup.string().required("Required"),
    gender: yup.string().required().min(1),
    home_church: yup.string(),
    reg_no: yup.string().required("Required").min(13, "Invalid").matches(reg_numberReg, {message: "Please enter a valid Reg Number(format: ABC123-4567/2020)"}),
    phone_number: yup.string().required("Required").min(10, "Invalid Phone Number").max(10, 'Invalid Phone number').
        test("Check prefix", function () {
            let code = "07";
            let code_2 = "01";
            let num = this.parent["phone_number"];
            // console.log(carriercode, blnum);
            if (code && num) {
                if((num.startsWith(code) || num.startsWith(code_2) ) && containsOnlyNumbers(num)){
                    return true;
                }
                else{
                    return false;
                }
            }
        }),
    next_of_kin: yup.string().required("Required").min(10, "Invalid Phone Number").max(10, 'Invalid Phone number').
        test("Check prefix", function () {
            let code = "07";
            let code_2 = "01";
            let num = this.parent["next_of_kin"];
            // console.log(carriercode, blnum);
            if (code && num) {
                if((num.startsWith(code) || num.startsWith(code_2) ) && containsOnlyNumbers(num)){
                    return true;
                }
                else{
                    return false;
                }
            }
        }),

})

export const  GenerateFamiliesSchema = yup.object().shape({
    numberOfFamilies: yup.number().required("Required")
})

export const CreateTicketSchema = yup.object().shape({
    manager: yup.string().required('Required'),
    name: yup.string().required('Required'),
    contact_phone: yup.string().required('Required')
})

export const PurchaseTIcketSchema = yup.object().shape({
    first_name: yup.string().required('Required').min(3, 'Enter a valid name'),
    last_name: yup.string().required('Required').min(3, 'Enter a valid name'),
    email: yup.string().email("Please Enter a valid email").matches(emailReg, {message: "Please enter a valid email"}).required("Required"),
    ticket_type: yup.string().required('Required'),
    phone: yup.string().required("Required").min(10, "Invalid Phone Number").max(10, 'Invalid Phone number').
        test("Check prefix", function () {
            let code = "07";
            let code_2 = "01";
            let num = this.parent["phone"];
            // console.log(carriercode, blnum);
            if (code && num) {
                if((num.startsWith(code) || num.startsWith(code_2) ) && containsOnlyNumbers(num)){
                    return true;
                }
                else{
                    return false;
                }
            }
        }),

})

export const PurchaseLessonSchema = yup.object().shape({
    first_name: yup.string().required('Required').min(3, 'Enter a valid name'),
    last_name: yup.string().required('Required').min(3, 'Enter a valid name'),
    type: yup.string().required('Required'),
    phone: yup.string().required("Required").min(10, "Invalid Phone Number").max(10, 'Invalid Phone number').
        test("Check prefix", function () {
            let code = "07";
            let code_2 = "01";
            let num = this.parent["phone"];
            // console.log(carriercode, blnum);
            if (code && num) {
                if((num.startsWith(code) || num.startsWith(code_2) ) && containsOnlyNumbers(num)){
                    return true;
                }
                else{
                    return false;
                }
            }
        }),
})

export const ChangePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required('Required'),
    newPassword: yup.string().required('Required').min(7, 'Minuimum of 7 characters'),
    confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match')
})

export const PaymentSchema = yup.object().shape({
    contribution: yup.string().required('Required'),
    amount: yup.number().required('Required').min(1, 'Minimum is 1'),
    phone: yup.string().required("Required").min(10, "Invalid Phone Number").max(10, 'Invalid Phone number').
        test("Check prefix", function () {
            let code = "07";
            let code_2 = "01";
            let num = this.parent["phone"];
            // console.log(carriercode, blnum);
            if (code && num) {
                if((num.startsWith(code) || num.startsWith(code_2) ) && containsOnlyNumbers(num)){
                    return true;
                }
                else{
                    return false;
                }
            }
        }),

})

export const ResetEmailSchema = yup.object().shape({
    email: yup.string().email("Please Enter a valid email").matches(emailReg, {message: "Please enter a valid email"}).required("Required"),
})

export const GiveCodeSchema = yup.object().shape({
    code: yup.string().required("Required"),
})

export const RetrievePasswordSchema = yup.object().shape({
    password1: yup.string().required("Required").min(7, "Minimum of 7 charachters"),
    password2: yup.string().required("Required").oneOf([yup.ref('password1')], 'Passwords must match'),
})

export const CreateBookSchema = yup.object().shape({
    title: yup.string().required("Required").min(3, "Minimum of 3 Characters"),
    no_of_copies: yup.number().required().min(1, 'Minimum is 1'),
    price: yup.number().required().min(1, "Minimum is 1")
})