import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import Toast from './Toaster';
import TextField from '@mui/material/TextField';
import ReactLoading from 'react-loading';
import toast from 'react-hot-toast';
import mode from './Mode';
import { ResetEmailSchema } from '../schemas';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const GiveEmail = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/general/' : 'http://127.0.0.1:8000/general/';

    const onSubmit = () =>{
        setIsLoading(true)
        var email = values.email
        axios.post(baseurl+'check-email/', {
            email: values.email

        }).then((resp)=>{
            console.log(resp)

            //send email
             //sending the ticket_id via email
            var templateParams = {
                email: values.email,
                code: resp.data.code
            }

            emailjs.send('service_5yfw0xh', 'template_tc1b9hf', templateParams, 'Y_h02AB2ElujhmJ37')
            .then((result) => {
                //console.log(result.text);
                toast.success('A code has been sent to your email', {
                    duration: 2000
                })
                setTimeout(()=>{
                    setIsLoading(false)
                    navigate('/reset-give-code', {state: {code: resp.data.code, email: email}})
                }, 4000)
            }, (error) => {
                toast.error('An error occurred', {
                    duration: 2000
                })
                setIsLoading(false)
                console.log(error.text);
            });
            


            
        }).catch((err)=>{
            if(err.response.data.Msg){
                toast.error('Email provided is not registered.')
            }else{
                toast.error('An error occurred')
            }
            console.log(err)
            setIsLoading(false)
        })
    }
    const {values, errors,  handleBlur, touched, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ResetEmailSchema,
        onSubmit
    })

    return(
        <>
            <div className='container'>
                <Toast/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="content-section row d-flex flex-column align-items-center justify-content-center my-auto" style={{backgroundColor: "transparent", border: 'none'}}>
                    <div className="content-section content col-md-5 my-auto" style={{backgroundColor: "white"}}>
                
                        <form method="POST" className='forms'>
                        
                            <fieldset className='mx-auto'>
                                <legend className="border-bottom mb-4" style={{color:'#228bdf'}}>Reset Password</legend>
                                    <div className='row justify-content-center'>
                                        <div className='col-md-9'>
                                            <TextField 
                                                fullWidth required 
                                                error={errors.email && touched.email}
                                                id="standard-basic" 
                                                label="Registered Email" 
                                                variant="standard" 
                                                style={{marginLeft: '5px', borderColor: 'red'}}
                                                name = 'email'
                                                value = {values.email}
                                                onChange = {handleChange}
                                                onBlur = {handleBlur}
                                            />
                                            { errors.email && touched.email && <p className='error'>{errors.email}</p>}
                                            
                                        </div>
                                        
                                    </div>                          
                                    
                            </fieldset>
                            <div className="form-group pt-3">
                                <button className="btn btn-outline-info" type="submit" disabled={isLoading} style={{borderRadius: '0.5rem'}} onClick={handleSubmit}> {isLoading ? <ReactLoading type="spin" color="rgb(11, 43, 66)" height={25} width={25} />: "Submit"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GiveEmail;