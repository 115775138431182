import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import Overlay from '../../overlay';
import Loading from '../../loading';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../Slices/auth';
import Toast from '../../Toaster';
import mode from '../../Mode';

const NoSubscribers = ()=>{
    return(
        <div style={{textAlign: 'center'}} className='content'>
            <br/>
            <h3 className='display-3' style={{color: '#fc8181'}}>No Subscribers</h3>
            <br/>
        </div>
    )
}

const Subscribers = () =>{
    const [isLoading, setIsLoading] = useState(true)
    const [subscribers, setSubscribers] = useState([])
    const token = useSelector((state)=>state.auth.token)
    const userType = useSelector((state)=>state.auth.userType)
    const baseurl = mode ? 'https://backend.portal.jkusdachurch.org/ss/': 'http://127.0.0.1:8000/ss/';
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const config = {
        headers: {
            'Content-Type':'application/json'
        }
    }

    if(token){
        config.headers['Authorization'] = `Token ${token}`;
    }

    useEffect(()=>{
        if(!(userType.includes('Sabbath Schl Admin') || userType.includes('Super Admin'))){
            navigate('/dashboard')
        }
        axios.get(baseurl+'lessonSubscriber/', config).then((resp)=>{
            //console.log(resp.data)
            setSubscribers(resp.data)
            setIsLoading(false)
        }).catch((err)=>{
            console.error(err)
            setIsLoading(false)
            if(err.response.status == 401){
                dispatch(logoutUser())
                navigate('/login', {state: {reason: 'Unauthorized'}})
            }
        })
    }, [])

    return(
        <div className='App'>
             <Overlay/>
             <Toast/>
             <div className='main-content'>
                <br/>
                <h4 className='display-6'>Subscribers</h4>
                {
                    isLoading ? <Loading/>:
                        subscribers.length < 1 ? <NoSubscribers/>:
                        <>
                            <button className='btn btn-primary'>Close Lesson Subscription</button>
                            <br/>
                            <table className='table table-secondary table-bordered table-striped table-hover'>
                                <thead>
                                    <tr style={{color: '#29b8b0', fontSize: '1.02em'}}>
                                        <td>NAME</td>
                                        <td>PHONE</td>
                                        <td>LESSON TYPE</td>
                                    </tr>
                                </thead>
                                    <tbody>
                                        {
                                            subscribers.map((subscriber, index)=>{
                                                return(
                                                    <tr>
                                                        <td>{subscriber.first_name} {subscriber.last_name}</td>
                                                        <td>{subscriber.phone}</td>
                                                        <td>{subscriber.lesson_type}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                            </table>
                        </>
                }
             </div>
        </div>
    )
}

export default Subscribers